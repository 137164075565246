import React, { Component } from "react";
import { connect } from "react-redux";
import MenuItem from "../Menu/MenuItem";
import { menuItems } from "../Menu/menuItems";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";
import logo from "../../Assests/RebelSkool.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Login from "../Login/LoginNew";
import Language from "../Language/Language";
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
import { FiShoppingCart } from "react-icons/fi";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
// import "../NavTest/NavTest.css";

// import { FiTrash2 } from "react-icons/fi";
// import { Redirect } from "react-router-dom";
// import { Sidebar } from "primereact/sidebar";
// import LoginForHeader from "../Login/LoginforHeader";
// import { StatusALert } from "../StatusAlert/StatusAlert";
// import Flag from "../Language/Flag";
// import Navbar from "../Menu/Navbar";
// import NavTest from "../NavTest/NavTest";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { GrClose } from "react-icons/gr";
// import { RiArrowDownSFill } from "react-icons/ri";
// import Cart from "../Shop/Cart";
// import TranslationFile from "../15-Translation/locale/Translation.json";
// import "../00-Corporate Page/CorporateMainPage.css";
// import "../Menu/Menu.css";
// import "../Steps/Test.css";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};

let userSelectedLanguage;
let totalPriceValue = 0;
let finalCheckoutArray = [];
let mappingCartArray = [];
let cartValuesLocalData;
let mappingCartArrayLength = 0;
let itemsForPostPayson = [];
let paysonSnippet = "",
  showStatusAlert = true;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false,
      totalPrice: 0,
      loginSuccess: false,
      dummy: false,
      matches: window.matchMedia("(min-width: 1019px)").matches,
      sidebarVisible: false,
      showPaysonSnippet: false,
    };

    this.proceedToPaymentPage = this.proceedToPaymentPage.bind(this);
  }

  refresh() {
    let possibleNavigationPagesList = ["My Dashboard", "Company Information"];
    let possibleNavigationPagesListString = JSON.stringify(
      possibleNavigationPagesList
    );

    localStorage.setItem(
      "possibleAccessablePages",
      possibleNavigationPagesListString
    );

    setTimeout(() => {
      window.location.reload(false);
    }, 10);
  }

  handleRemove(id) {
    const { login } = this.props;
    let cartValuesLocalData =
      JSON.parse(localStorage.getItem("cartValues")) || [];
    let filteredPeople =
      login != undefined &&
      cartValuesLocalData.filter((item) => item.userID == login.userID);
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;

    //Checking how many are there in the
    // cart other than the user deleted package
    let otherPackages = cartArrayList.filter((item) => item.packageId != id);

    // Filtering the accounting packages from the other packages
    let accPackagesPresentInCart =
      otherPackages &&
      otherPackages.filter((item) => item.customerType == "Accounting Company");

    let isAddOnPresent = false;
    accPackagesPresentInCart &&
      accPackagesPresentInCart.forEach((product) => {
        if (product.packageName == "Extra User") isAddOnPresent = true;
      });
    let accPackagesPresentInCartWithoutAddOn = [];

    if (isAddOnPresent) {
      accPackagesPresentInCartWithoutAddOn =
        accPackagesPresentInCart &&
        accPackagesPresentInCart.filter(
          (item) =>
            item.customerType == "Accounting Company" &&
            item.packageName != "Extra User"
        );
    }
    let removeAccPackageApplicable = false;
    if (accPackagesPresentInCartWithoutAddOn.length > 0)
      removeAccPackageApplicable = true;

    if (removeAccPackageApplicable) {
      let arr = [],
        cartWithUserID = [];

      // // Removing Package from Cart
      arr = cartArrayList.filter((item) => item.packageId != id);
      cartWithUserID = [
        {
          userID: login.userID,
          cartArray: arr,
        },
      ];

      localStorage.setItem("cartValues", []);
      localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));
      this.props.callBack(arr, "", "", false);
    } else {
      let arr = [],
        cartWithUserID = [];

      // // Removing the Add On Package from Cart if the user removes Accounting Package
      arr = cartArrayList.filter(
        (item) => item.packageId != id && item.packageName != "Extra User"
      );
      cartWithUserID = [
        {
          userID: login.userID,
          cartArray: arr,
        },
      ];

      localStorage.setItem("cartValues", []);
      localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));
      this.props.callBack(arr, "", "", false);
    }
  }

  handleChange(item, d) {
    const { login } = this.props;
    cartValuesLocalData = JSON.parse(localStorage.getItem("cartValues")) || [];
    let filteredPeople =
      login != undefined &&
      cartValuesLocalData.filter((item) => item.userID == login.userID);
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;
    let cartValuesHandleChange = cartArrayList;

    let ind = -1;

    cartValuesHandleChange.forEach((data, index) => {
      if (data.packageId === item.packageId) ind = index;
    });
    cartValuesHandleChange[ind].quantity =
      cartValuesHandleChange[ind].quantity + d;

    let cartWithUserID = [
      {
        userID: login.userID,
        cartArray: cartValuesHandleChange,
      },
    ];

    localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));

    if (cartValuesHandleChange[ind].quantity == 0) {
      this.handleRemove(cartValuesHandleChange[ind].packageId);
    }

    this.props.callBack(item, "", "", false);
  }

  handlePrice() {
    const { login } = this.props;
    let filteredPeople = [];
    if (localStorage.getItem("cartValues") != "") {
      let cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      filteredPeople =
        login != undefined &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
    }
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;
    let priceValue = 0;
    // cartValuesLocalData != undefined && cartValuesLocalData != []
    if (cartArrayList.length != undefined) {
      cartArrayList.filter(
        (item) => (priceValue += item.quantity * item.price)
      );
    }
    totalPriceValue = priceValue;
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    const { login, showWIPStatus } = this.props;

    showWIPStatus.values = false;

    let filteredPeople = [];
    if (localStorage.getItem("cartValues") != "") {
      cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      filteredPeople =
        login != undefined &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
    }

    mappingCartArray =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;

    let loginLocalData = localStorage.getItem("rebelSkoolUser");
    // console.log(loginLocalData, "!!!");
    if (loginLocalData != "undefined") {
      loginLocalData = JSON.parse(loginLocalData);
      if (loginLocalData) {
        this.setState({ loginSuccess: true });
      } else {
        this.setState({ loginSuccess: false });
      }
    } else {
      this.setState({ loginSuccess: false });
    }

    this.setState({
      matches: true,
    });
  }

  componentWillReceiveProps() {
    cartValuesLocalData = JSON.parse(localStorage.getItem("cartValues")) || [];

    const { login } = this.props;

    let filteredPeople =
      login != undefined &&
      cartValuesLocalData.filter((item) => item.userID == login.userID);

    mappingCartArray =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;

    let loginLocalData = JSON.parse(localStorage.getItem("rebelSkoolUser"));
    if (loginLocalData) {
      this.setState({ loginSuccess: true });
    } else {
      this.setState({ loginSuccess: false });
    }

    this.setState({
      matches: true,
    });
  }

  componentDidMount() {
    const { prices, login } = this.props;
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(min-width: 1019px)")
      .addEventListener("change", handler);
  }

  responsiveLoginSidebar() {
    this.setState({ sidebarVisible: true });
  }
  proceedToPaymentPage() {
    const { sessionDetails, login } = this.props;
    const annualReport = "/annualreport/";
    const session = sessionDetails["sessionDetails"].values.uuid;
    const link = "/payment";

    this.props.callBack(annualReport, session, link, true);
  }

  callback = (annualReport, session, link, status) => {
    this.props.callBack(annualReport, session, link, true);
    // this.props.showStatusAlertFunc(true);
  };

  showStatusAlertFunc = (val) => {
    // console.log(val, "<< VAL");
    this.props.showStatusAlertFunc(true);
  };

  render() {
    const { prices, login, pricesToLogin, showWIPStatus } = this.props;
    const { sidebarVisible } = this.state;
    this.handlePrice();
    if (mappingCartArray.length == 0) {
      mappingCartArrayLength = 0;
    } else {
      mappingCartArrayLength = mappingCartArray.length;
    }
    return (
      <div>
        {this.state.matches && (
          <div>
            <StickyHeader
              // This is the sticky part of the header.  style={{border:'5px solid black'}}
              header={
                <header>
                  {showWIPStatus.values && (
                    <div className="status-alert-div-header">
                      {/* <StatusALert /> */}
                    </div>
                  )}

                  <div>
                    <div className="nav-area">
                      <nav className="navigation">
                        {/* RebelSkool Logo */}
                        <Link to="/">
                          <Image
                            src={logo}
                            alt="Image"
                            width="200"
                            className="navbar-logo"
                            onClick={() => this.refresh()}
                          />
                        </Link>
                        {/* List of Menus */}
                        <div className="navigation-menu">
                          <ul className="navigation-menu-ul">
                            {menuItems.map((menu, index) => {
                              const depthLevel = 0;
                              return (
                                <li>
                                  <MenuItem
                                    items={menu}
                                    key={index}
                                    depthLevel={depthLevel}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="corp-TryForFree">
                          <Button className="corp-btn-tryfree">
                            Try For Free
                          </Button>
                        </div>

                        {/* Login Button */}
                        <Login callback={this.callback} />

                        {/* Cart Icon */}
                        <div className="cart-nav-link-div">
                          <ul style={{ display: "flex", position: "relative" }}>
                            <li className="cart-nav-link">
                              <a className="cart-user-greeting">
                                <div className="cart">
                                  <span>
                                    <FiShoppingCart
                                      onClick="/cart"
                                      className="shopping-cart"
                                    />
                                  </span>
                                  {mappingCartArrayLength > 0 && (
                                    <span>{mappingCartArrayLength}</span>
                                  )}
                                </div>
                              </a>
                              {mappingCartArray.length > 0 ? (
                                <div className="cart-dropdownT">
                                  {mappingCartArray != undefined && (
                                    <div>
                                      <div>
                                        <article>
                                          <div className="scrollpanel-demo">
                                            <div>
                                              <ScrollPanel
                                                style={{ height: "500px" }}
                                                // style={{ maxHeight: '1200px',height:'100%' }}
                                                className="custombar1"
                                              >
                                                {mappingCartArray &&
                                                  mappingCartArray.map(
                                                    (item) => (
                                                      <div className="cart-first">
                                                        <div
                                                          className="cart_box"
                                                          key={item.packageId}
                                                        >
                                                          <ul
                                                            className="cart_box_ul"
                                                            style={{
                                                              listStyle: "none",
                                                            }}
                                                          >
                                                            <li className="cart_box_li">
                                                              <a>
                                                                <div
                                                                  className="cart-div1-drop"
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                  }}
                                                                >
                                                                  <div className="cart-col-1">
                                                                    {item.text1}
                                                                    <br></br>
                                                                    {/* {item.desc1}
                                                                <br></br> */}
                                                                    SEK{" "}
                                                                    {item.price}
                                                                    .
                                                                  </div>

                                                                  <div className="cart-col-4">
                                                                    <Button
                                                                      icon="pi pi-trash"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                      // rounded
                                                                      text
                                                                      aria-label="Cancel"
                                                                      className="cart-col-4"
                                                                      onClick={() =>
                                                                        this.handleRemove(
                                                                          item.packageId
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>

                                                                <div
                                                                  className="cart-div2-drop"
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                  }}
                                                                >
                                                                  <div className="cart-col-2">
                                                                    <span>
                                                                      <Button
                                                                        icon="pi pi-minus"
                                                                        text
                                                                        outlined
                                                                        aria-label="Cancel"
                                                                        onClick={() =>
                                                                          this.handleChange(
                                                                            item,
                                                                            -1
                                                                          )
                                                                        }
                                                                      />
                                                                    </span>
                                                                    <span>
                                                                      <InputNumber
                                                                        style={{
                                                                          width:
                                                                            "2em",
                                                                          height:
                                                                            "2em",
                                                                        }}
                                                                        value={
                                                                          item.quantity
                                                                        }
                                                                        className="cart-item-amount22"
                                                                      />
                                                                    </span>
                                                                    <span>
                                                                      <Button
                                                                        icon="pi pi-plus"
                                                                        text
                                                                        aria-label="Cancel"
                                                                        className="cart-plus-button"
                                                                        onClick={() =>
                                                                          this.handleChange(
                                                                            item,
                                                                            +1
                                                                          )
                                                                        }
                                                                      />
                                                                    </span>
                                                                  </div>

                                                                  <div className="cart-col-3">
                                                                    <p>
                                                                      SEK{" "}
                                                                      {item.price *
                                                                        item.quantity}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </a>
                                                            </li>
                                                          </ul>
                                                          <Divider className="cart_divider" />
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                              </ScrollPanel>
                                            </div>
                                          </div>

                                          <div className="cart-total-price-div">
                                            <div className="cart-totalprice">
                                              <div className="cart-total-text">
                                                Total Price
                                                {/* {TranslationFile.TotalPriceText[userSelectedLanguage]} */}
                                              </div>
                                              <div className="cart-total-end-price">
                                                SEK {totalPriceValue}
                                              </div>
                                            </div>

                                            <div className="cart-checkout">
                                              {/* <Link to="/payment"> */}
                                              <Button
                                                label="Go To Checkout"
                                                rounded
                                                className="cart-checkout-btn22"
                                                onClick={() =>
                                                  this.proceedToPaymentPage()
                                                }
                                              />

                                              {/* </Link> */}
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="cart-dropdownT">
                                  <div style={{ padding: "6% 21%" }}>
                                    Your Cart is empty
                                  </div>
                                </div>
                              )}

                              <div></div>
                            </li>
                          </ul>
                        </div>
                        {/* Language Dropdown */}
                        <Language />
                      </nav>
                    </div>
                  </div>
                </header>
              }
            >
              <section></section>
            </StickyHeader>
          </div>
        )}
        {!this.state.matches && (
          <div>
            <div
            // class="wrapper"
            >
              <StickyHeader
                // This is the sticky part of the header.
                header={
                  <header>
                    <nav>
                      <input
                        type="checkbox"
                        id="menu"
                        name="menu"
                        class="m-menu__checkbox"
                      />
                      {/* <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5,1fr)",
                }}
              > */}
                      <Row
                        className="header-row-grid"
                        style={{ width: window.innerWidth }}
                      >
                        <Col className="header-row-grid-hamburger" xs={1}>
                          <label class="m-menu__toggle" for="menu">
                            <svg
                              width="35"
                              height="35"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#112D4E"
                              stroke-width="2"
                              stroke-linecap="butt"
                              stroke-linejoin="arcs"
                              className="hamburger-svg"
                            >
                              <line x1="3" y1="12" x2="21" y2="12"></line>
                              <line x1="3" y1="6" x2="21" y2="6"></line>
                              <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                          </label>
                        </Col>
                        <Col
                          className="header-row-grid-logo"
                          xs={8}
                        // sm={4}
                        // md={4}
                        // lg={4}
                        // xl={4}
                        >
                          <Link to="/" className="link-for-navbar-logo">
                            <img
                              src={logo}
                              alt="Image"
                              width="200"
                              className="navbar-logo"
                            // onClick={() => this.refresh()}
                            ></img>
                          </Link>
                        </Col>
                        <Col className="header-row-grid-login-icon" xs={1}>
                          {/* Login Icon button */}
                          {this.state.loginSuccess && (
                            <div class="nav-div-wrapper">
                              <input
                                type="checkbox"
                                id="menu2"
                                name="menu2"
                                class="tryMenu"
                              />
                              <label class="user-m-menu__toggle" for="menu2">
                                {/* <Button icon="pi pi-user" rounded text severity="info" aria-label="User" className="user-m-menu__toggle"/> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1.5em"
                                  viewBox="0 0 512 512"
                                  className="user-login-success"
                                >
                                  <path d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z" />
                                </svg>
                              </label>
                              <label
                                class="user-m-menu__overlay"
                                for="menu2"
                              ></label>
                              <div class="user-m-menu">
                                <div class="user-m-menu__header">
                                  <label
                                    class="user-m-menu__toggle"
                                    for="menu2"
                                  >
                                    <svg
                                      width="35"
                                      height="35"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#000000"
                                      stroke-width="2"
                                      stroke-linecap="butt"
                                      stroke-linejoin="arcs"
                                    >
                                      <line
                                        x1="18"
                                        y1="6"
                                        x2="6"
                                        y2="18"
                                      ></line>
                                      <line
                                        x1="6"
                                        y1="6"
                                        x2="18"
                                        y2="18"
                                      ></line>
                                    </svg>
                                  </label>
                                  <span>My Pages</span>
                                  <div></div>
                                </div>
                                <div class="user-menu-greeting">Hi, Admin</div>
                                <ul>
                                  <li>
                                    <label>Edit Profile</label>
                                  </li>
                                  <li>
                                    <label>Manage Company / User Info</label>
                                  </li>
                                  <li>
                                    <label>Dashboard</label>
                                  </li>
                                  <li>
                                    <label>Sign Out</label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                          {!this.state.loginSuccess && (
                            // <Button icon="pi pi-user" rounded outlined severity="info" aria-label="User" />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              // height="1.5em"
                              viewBox="0 0 448 512"
                              className="not-logged-in-user-icon"
                            >
                              <path
                                onClick={() => this.responsiveLoginSidebar()}
                                d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 
                      256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 
                      512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 
                      368.2 304 269.7 304H178.3z"
                              />
                            </svg>
                          )}
                          {sidebarVisible == true ? (
                            <div>{/* <LoginForHeader /> */}</div>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col className="header-row-grid-lang" xs={1}>
                          <Language />
                        </Col>
                        <Col className="header-row-grid-cart" xs={1}>
                          {/* Cart Icon */}
                          <div className="cart-nav-link-div">
                            <ul
                              style={{ display: "flex", position: "relative" }}
                            >
                              <li className="cart-nav-link">
                                <a className="cart-user-greeting">
                                  <div className="cart">
                                    <span>
                                      <FiShoppingCart
                                        onClick="/cart"
                                        className="shopping-cart"
                                      />
                                    </span>
                                    <span>{mappingCartArrayLength}</span>
                                  </div>
                                </a>
                                {mappingCartArray > 0 ? (
                                  <div className="cart-dropdownT">
                                    {mappingCartArray != undefined && (
                                      <div>
                                        <div>
                                          <article>
                                            <div className="scrollpanel-demo">
                                              <div>
                                                <ScrollPanel
                                                  style={{ height: "500px" }}
                                                  // style={{ maxHeight: '1200px',height:'100%' }}
                                                  className="custombar1"
                                                >
                                                  {mappingCartArray &&
                                                    mappingCartArray.map(
                                                      (item) => (
                                                        <div className="cart-first">
                                                          <div
                                                            className="cart_box"
                                                            key={item.packageId}
                                                          >
                                                            <ul
                                                              className="cart_box_ul"
                                                              style={{
                                                                listStyle:
                                                                  "none",
                                                              }}
                                                            >
                                                              <li className="cart_box_li">
                                                                <a>
                                                                  <div
                                                                    className="cart-div1-drop"
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                    }}
                                                                  >
                                                                    <div className="cart-col-1">
                                                                      {
                                                                        item.text1
                                                                      }
                                                                      <br></br>
                                                                      {/* {item.desc1}
                                                            <br></br> */}
                                                                      SEK{" "}
                                                                      {
                                                                        item.price
                                                                      }
                                                                      .
                                                                    </div>

                                                                    <div className="cart-col-4">
                                                                      <Button
                                                                        icon="pi pi-trash"
                                                                        style={{
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                        // rounded
                                                                        text
                                                                        aria-label="Cancel"
                                                                        className="cart-col-4"
                                                                        onClick={() =>
                                                                          this.handleRemove(
                                                                            item.packageId
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </div>

                                                                  <div
                                                                    className="cart-div2-drop"
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                    }}
                                                                  >
                                                                    <div className="cart-col-2">
                                                                      <span>
                                                                        <Button
                                                                          icon="pi pi-minus"
                                                                          text
                                                                          outlined
                                                                          aria-label="Cancel"
                                                                          onClick={() =>
                                                                            this.handleChange(
                                                                              item,
                                                                              -1
                                                                            )
                                                                          }
                                                                        />
                                                                      </span>
                                                                      <span>
                                                                        <InputNumber
                                                                          style={{
                                                                            width:
                                                                              "2em",
                                                                            height:
                                                                              "2em",
                                                                          }}
                                                                          value={
                                                                            item.quantity
                                                                          }
                                                                          className="cart-item-amount22"
                                                                        />
                                                                      </span>
                                                                      <span>
                                                                        <Button
                                                                          icon="pi pi-plus"
                                                                          text
                                                                          aria-label="Cancel"
                                                                          className="cart-plus-button"
                                                                          onClick={() =>
                                                                            this.handleChange(
                                                                              item,
                                                                              +1
                                                                            )
                                                                          }
                                                                        />
                                                                      </span>
                                                                    </div>

                                                                    <div className="cart-col-3">
                                                                      <p>
                                                                        SEK{" "}
                                                                        {item.price *
                                                                          item.quantity}
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </a>
                                                              </li>
                                                            </ul>
                                                            <Divider className="cart_divider" />
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                </ScrollPanel>
                                              </div>
                                            </div>

                                            <div className="cart-total-price-div">
                                              <div className="cart-totalprice">
                                                <div className="cart-total-text">
                                                  Total Price
                                                </div>
                                                <div className="cart-total-end-price">
                                                  SEK {totalPriceValue}
                                                </div>
                                              </div>

                                              <div className="cart-checkout">
                                                <Link>
                                                  <Button
                                                    label="Go To Checkout"
                                                    rounded
                                                    className="cart-checkout-btn22"
                                                    onClick={() =>
                                                      this.proceedToPaymentPage.bind(
                                                        this
                                                      )
                                                    }
                                                  />
                                                </Link>
                                              </div>
                                            </div>
                                          </article>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="cart-dropdownT">
                                    <div style={{ padding: "6% 21%" }}>
                                      Your Cart is empty
                                    </div>
                                  </div>
                                )}

                                <div></div>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      {/* </div> */}
                      {/* <!-- Hamburger Menu Icon Overlay --> */}

                      <label class="m-menu__overlay" for="menu"></label>

                      <div class="m-menu">
                        {/* <!-- Menu Text in Sidebar --> */}
                        <div class="m-menu__header">
                          <label class="m-menu__toggle" for="menu">
                            <svg
                              width="35"
                              height="35"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#000000"
                              stroke-width="2"
                              stroke-linecap="butt"
                              stroke-linejoin="arcs"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </label>
                          <span>MENU</span>
                        </div>
                        {/* <!-- Menu Text in Sidebar --> */}

                        <ul>
                          <li>
                            <label class="a-label__chevron" for="item-2">
                              Services
                            </label>
                            <input
                              type="checkbox"
                              id="item-2"
                              name="item-2"
                              class="m-menu__checkbox"
                            />
                            <div class="m-menu">
                              <div class="m-menu__header">
                                <label class="m-menu__toggle" for="item-2">
                                  <svg
                                    width="35"
                                    height="35"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="2"
                                    stroke-linecap="butt"
                                    stroke-linejoin="arcs"
                                  >
                                    <path d="M19 12H6M12 5l-7 7 7 7" />
                                  </svg>
                                </label>
                                <span>Services</span>
                              </div>
                              <ul>
                                <li>
                                  <label>Annual Report</label>
                                </li>
                                <li>
                                  <label
                                    class="a-label__chevron"
                                    for="item-2-3"
                                  >
                                    Income Declaration
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="item-2-3"
                                    name="item-2"
                                    class="m-menu__checkbox"
                                  />
                                  <div class="m-menu">
                                    <div class="m-menu__header">
                                      <label
                                        class="m-menu__toggle"
                                        for="item-2-3"
                                      >
                                        <svg
                                          width="35"
                                          height="35"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#000000"
                                          stroke-width="2"
                                          stroke-linecap="butt"
                                          stroke-linejoin="arcs"
                                        >
                                          <path d="M19 12H6M12 5l-7 7 7 7" />
                                        </svg>
                                      </label>
                                      <span>Income Declaration </span>
                                    </div>
                                    <ul>
                                      <li>
                                        <label>SRU</label>
                                      </li>
                                      <li>
                                        <label>NE</label>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <label>How it works</label>
                          </li>
                          <li>
                            <label>Prices</label>
                          </li>
                          <li>
                            <label>FAQ</label>
                          </li>
                          <li>
                            <label>Contact Us</label>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </header>
                }
              ></StickyHeader>
            </div>
            <div></div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Header);
