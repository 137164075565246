import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import StatusAlert from "../src/Pages/StatusAlert/StatusAlert";
import Config from "../src/Configuration/Config";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css"; //icons
import "primeflex/primeflex.css";
import axios from "axios";
import Cookie from "./Pages/Cookie/Cookie";

axios.defaults.baseURL = Config.backend.url;
let statusBar = "";

axios
  .get("/siteOwner/getStatusBand?appId=" + 1)
  .then((response) => {
    let respData = response.data;
    statusBar = respData.activate;
    localStorage.setItem("StatusBand", JSON.stringify(respData));
    ReactDOM.render(
      <React.StrictMode>
        <Cookie />
        {statusBar && (
          <div className="statusAlterBar">
            <StatusAlert />
          </div>
        )}

        <div className={statusBar ? "app2" : "app"}>
          <App />
        </div>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Cookie />
        <div className={statusBar ? "app2" : "app"}>
          <App />
        </div>
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
