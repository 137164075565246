import React, { Component } from "react";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footerpage from "../Footerpage/Footerpage";
import TranslationFile from "../15-Translation/locale/Translation.json";
import "./Credits.css";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
let userSelectedLanguage;

class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToContactUs() {
    this.props.callBack();
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <NavBarHeader isCorpPortal={true} />
        <div className="ID_Credits">
          <br></br>
          <br></br>

          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              fontFamily: "Poppins-Medium",
              fontSize: "30px",
              textAlign: "left",
            }}
          >
            <span style={{ color: "red" }}>Credits</span>
          </div>
          <br></br>
          <div>
            {/* Image by <a href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm#from_view=detail_alsolike">Freepik</a> */}
            Image by
            <a
              href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.html"
              target="_blank"
            >
              Freepik
            </a>
            <br></br>
            Image by
            <a
              href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm#"
              target="_blank"
            >
              Freepik
            </a>
            <br></br>
            <a
              href="https://www.freepik.com/free-photo/thoughtful-businessman-sitting-with-open-laptop-computer-looking-worried-while-thinking-about-planning-top-view_1202129.htm?query=accounting#from_view=detail_alsolike%22%3EImage"
              target="_blank"
            >
              Image by snowing
            </a>{" "}
            on Freepik
          </div>
        </div>
        <ScrolltoTop />
        {/* <Footerpage /> */}
        <Footer_contact_tab sideBar={this.state.sideBarStatus} isCorpPortal={false} isHomePage={true}/>

      </div>
    );
  }
}

export default Credits;
