import React, { Component } from "react";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footerpage from "../Footerpage/Footerpage";
import TranslationFile from "../15-Translation/locale/Translation.json";
import { HashLink } from "react-router-hash-link";
import "./AboutUs.css";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";

let userSelectedLanguage;

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <NavBarHeader isCorpPortal={true} />
        <div className="ID_About-Us">
          <br></br>
          <br></br>

          {/* ------------------------------------------------------------------ */}
          <div class="cor-product" ref={this.whyRSRef}>
            <div id="cor-product-section">
              <h2 className="cor-product-h2">
                {TranslationFile.FooterwhyRBL[userSelectedLanguage]}
              </h2>
              <p id="cor-product-paragraph">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                <span>
                  <a href="/about-us/Why-RebelSkool" target>
                    more details
                  </a>
                </span>
              </p>
            </div>
          </div>

          {/* ----------------------------------------------------------------- */}

          <div
            class="cor-product"
            ref={this.pricePolicyRef}
            id="price-policy-div"
          >
            <div id="cor-product-section">
              <h2 className="cor-product-h2">
                {TranslationFile.NavbarPricePolicy[userSelectedLanguage]}
              </h2>
              <p id="cor-product-paragraph">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                <a href="/about-us/Price-Policy" target>
                  more details
                </a>
              </p>
            </div>
          </div>
          {/* ------------------------------------------------------------------ */}
          <div className="corp-mapdiv">
            <div className="corpMapMainDiv">
              <div className="corp-map-Locationdiv">
                <h5 className="corp-map-location-name">Gallstad, Sverige:</h5>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1794.3946919341654!2d13.42164845502346!3d57.66706613460003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465a83fde1af27f9%3A0x5b094da9b577e24a!2sRebelSkool%20enabling%20prosperITy%20AB!5e0!3m2!1sen!2sin!4v1712650510401!5m2!1sen!2sin"
                  className="cor-iframe1"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>

                <h5 className="corp-map-company-name">
                  RebelSkool Enabling prosperITy AB
                </h5>
                <div className="addressMap" style={{ lineHeight: "1.6" }}>
                  Org.Nr: 559015-5395<br></br>
                  Bollvagen 11A, Gallstad, Sverige<br></br>
                  Godkant for F-skatt<br></br>
                  info@rebelskool.com
                </div>
              </div>

              <div className="corp-map-Locationdiv">
                <h5 className="corp-map-location-name">Thanjavur, Indien:</h5>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.800696452567!2d79.13686537475292!3d10.749838259684212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baab983f60b63b1%3A0xc9089fe02e97325d!2sRebelskool%20Consulting%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1704792153060!5m2!1sen!2sin"
                  className="cor-iframe1"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>

                <h5 className="corp-map-company-name">
                  RebelSkool Consulting PVT LTD
                </h5>
                <div className="addressMap" style={{ lineHeight: "1.6" }}>
                  271, Fourth Street, Arokiya Nagar, NK Road,<br></br>
                  Thanjavur - 613006, Tamil Nadu, INDIA<br></br>
                  info@rebelskool.com
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrolltoTop />
        {/* <Footerpage /> */}
        <Footer_contact_tab sideBar={this.state.sideBarStatus} isCorpPortal={false} isHomePage={true}/>

      </div>
    );
  }
}
export default AboutUs;
