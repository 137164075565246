import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Dropdown } from "primereact/dropdown";
import { RiArrowDownSFill } from "react-icons/ri";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import { validateEmail } from "../Login/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import TranslationFile from "../15-Translation/locale/Translation.json";
import Footerpage from "../Footerpage/Footerpage";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Sidebar from "../Sidebar/Sidebar";
import "./MyDashboard.css";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import config from "../../Configuration/Config";
import { SplitButton } from 'primereact/splitbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';



let accRegNo = "",
  transformedArray = [];
let userSelectedLanguage;
let unPaidReportsArray = [];
let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;


let steps = [
  {
    content: 'Clicking on the Report Sharing tab allows you to view shared reports and their details.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashboardTab',
    title: 'Accordion',
    disableBeacon: true,
  },
  {
    content: 'Clicking on this company name will redirect you to the report.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashbaord-companyName',
    title: 'Company Name',
    disableBeacon: true,
  },
  {
    content: 'The user can share the report using this link and also grant access to others.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.linkshareTab',
    title: 'Link hare',
    disableBeacon: true,
  },
  {
    content: 'You can view the PDF file up to the completed process.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashboard-pdf-Id',
    title: 'Pdf',
    disableBeacon: true,
  },
];


let userDetailsByEmailIdRespData;
export default function MyDashboard() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  let userTimeZone = localStorage.getItem("userTimeZone");
  const history = useHistory();
  let isValidEmailIDCheck = false;
  let emptyProduct = {
    id: null,
    companyName: "",
    organizationNumber: "",
    financialYear: "",
    linkSharing: "",
    accountingCompanySharing: "",
    createdOn: "",
    createdBy: "",
    modifiedOn: "",
    modifiedBy: "",
    reportStatus: "",
  };
  let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

  const getSeverityActive = (Active) => {
    switch (Active) {
      case "Read Only":
        return "warning";

      case "Write":
        return "success";

      case "No Access":
        return "danger";
    }
  };


  const shareAccess = [
    { name: "Read Only", code: "Read Only" },
    { name: "Write", code: "Write" },
    { name: "No Access", code: "No Access" },
  ];

  const peopleWithAccess = [
    { name: "Read Only", code: "Read Only" },
    { name: "Write", code: "Write" },
    { name: "Remove Access", code: "Write" },
  ];

  const [shareEmail, setShareEmail] = useState(null);
  const [peopleselectedAccess, setPeopleSelectedAccess] = useState(
    peopleWithAccess[0]
  );

  const [statuses] = useState(["Draft", "Completed"]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [userDetails, setUserDetails] = useState(null);
  const [shareProductDialog, setShareProductDialog] = useState(false);
  const [sideBarOpenState, setSideBarOpenState] = useState(true);
  const [dashboardAPIResponse, setDashboardAPIResponse] = useState("");
  const [addEmailId, setAddEmailId] = useState("");
  const [addedEmailIdCount, setAddedEmailIdCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [shareEmailFocus, setShareEmailFocus] = useState(false);
  const [shareEmailDialogOverlap, setShareEmailDialogOverlap] = useState(true);
  const [isEmailIdAlreadyExists, setIsEmailIdAlreadyExists] = useState(false);
  const [linkSharedMessage, setLinkSharedMessage] = useState("");
  const [checked, setChecked] = useState(true);
  const [userList, setUserList] = useState('');
  const [companyMembers, setCompanyMembers] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [run, setRun] = useState(false);
  const [filters, setFilters] = useState({

    companyName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    organizationNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    financialYear: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    createdOn: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    modifiedOn: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    modifiedBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [inputFields, setInputFields] = useState([
    {
      fullName: "",
      fullNameDp: shareAccess && shareAccess[2].name,
    },
  ]);

  const [inputdata, setInputDate] = useState({
    emailId: "",
    accessLevel: shareAccess && shareAccess[2].name,
  });
  const [inputarr, setInputarr] = useState([]);
  const [selectedAccess, setSelectedAccess] = useState([
    {
      fullNameDp: shareAccess && shareAccess[2].name,
    },
  ]);

  //FOCUS
  const [emailIdFocus, setEmailIdFocus] = useState(false);
  const [isValidEmailId, setIsValidEmailId] = useState(false);
  const loginReducer = useSelector((state) => state.login);
  const sidebarReducer = useSelector((state) => state.sidebarReducer);
  let isAccCompany =
    (loginReducer.login.values.accountingCompany != null && loginReducer.login.values.accountingCompany != undefined)
      ? loginReducer.login.values.accountingCompany
      : false;
  const [activeIndex, setActiveIndex] = useState(isAccCompany ? 0 : 1);




  useEffect(() => {

    localStorage.setItem("currentPageName", "Dashboard");
    window.scrollTo(0, 0);
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = localData && JSON.parse(localData);
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (
      loginReducer.login.values != undefined &&
      userSessionAuthKeyReturnValue &&
      localObject.userID != undefined
    ) {
      axios
        .get(
          "/userDashboard/getLinkSharedDetails",
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let respData = response.data;
          if (respData.length == 0) {
            checkForSharedReports();
          } else {
            respData.map((i, idx) => {
              if (i.createdAtDateTime > i.modifiedAtDateTime) {
                return (i.modifiedAtDateTime = "");
              }
            });
            respData = respData.reverse();
            setDashboardAPIResponse(respData);
          }
        });
    }
  }, []);
  //If my reports has no data then we have to check if shared reports have any data.
  //If it has we habe to show shared reports tab
  //If shared reports also have no data. then we have to show my reports tab

  const isValidEmailID = (isValidEmailId) => {
    if (isValidEmailId) {
      setIsValidEmailId(true);
    } else {
      setIsValidEmailId(false);
    }
  };

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.createdOn = new Date(d.createdOn);
      return d;
    });
  };
  const getSeverityOrg = (product, status) => {
    switch (status) {
      case "Completed":
        return "success";

      case "Draft":
        return "warning";

      case "Paid":
        return "success";
      default:
        return null;
    }
  };

  const getSeverity = (status) => {
    let statusValue = "Draft";
    if (status) {
      statusValue = "Completed";
    }

    switch (statusValue) {
      case "Completed":
        return "success";

      case "Draft":
        return "warning";
    }
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteProduct = () => {
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    toast.current.show({
      severity: "success",
      summary: TranslationFile.SuccessfulText[userSelectedLanguage],
      detail: TranslationFile.ProductDeleted[userSelectedLanguage],
      life: 3000,
    });
  };

  const deleteSelectedProducts = () => {
    if (userSessionAuthKeyReturnValue) {
      let reportIds = "";
      selectedProducts.length &&
        selectedProducts.map((i, idx) => {
          if (reportIds == "") reportIds = i.reportId;
          else reportIds = reportIds + "," + i.reportId;
        });

      axios
        .get(
          "/annualReport/hideReportHeaders?reportIds=" + reportIds,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          axios
            .get(
              "/userDashboard/getLinkSharedDetails",
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              let respData = response.data;
              respData.map((i, idx) => {
                if (i.createdAtDateTime > i.modifiedAtDateTime) {
                  return (i.modifiedAtDateTime = "");
                }
              });
              respData = respData.reverse();
              setDashboardAPIResponse(respData);
            });
        });

      setDeleteProductsDialog(false);
      setSelectedProducts(null);
      toast.current.show({
        severity: "success",
        summary: TranslationFile.SuccessfulText[userSelectedLanguage],
        detail: TranslationFile.ReportDeletedSuces[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div>
        {value == options[0] && (
          <Button
            className="whole-delete"
            label={TranslationFile.dashboardDeleteBtn[userSelectedLanguage]}
            icon="pi pi-trash"
            severity="danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedProducts || !selectedProducts.length}
          />
        )}
      </div>
    );
  };
  const [accessValueRadio, setAccessValueRadio] = useState('')
  const op = useRef(null);

  const shareAccessOnClick = () => {
    let reqArray = [];

    companyMembers && companyMembers.forEach((value) => {
      let req = {
        reportId: value.reportId,
        createdByUserId: loginReducer.login.values.userID,
        userAccessLevel: accessValueRadio,
        emailId: value.emailId,
        isInsert: true,
      };
      reqArray.push(req)
    })
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/annualReport/saveReportAccessList",
          reqArray,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let temp = [];
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
              life: 3000,
            });
            temp = companyMembers
            temp.map((val) => {
              return val.accessLevel = accessValueRadio
            })
            temp && temp.map((val) => {
              setCompanyMembers((companyMembers) => [...companyMembers])
            })
          }
          setAccessValueRadio('')
          op.current.hide()
        });
    }


  }

  const leftToolbarTemplatebox2 = () => {
    return (
      <div>
        <Button
          type="button"
          label="Share to All Users"
          onClick={(e) => op.current.toggle(e)}
        />
        <OverlayPanel ref={op}>
          <div style={{ width: "200px" }}>
            <div style={{textAlign:'end'}}>
            <i className="pi pi-times" onClick={() => op.current.hide()}></i>
            </div>
            <div className="common-share-dash">
              <RadioButton
                inputId="readOnly"
                value="Read Only"
                onChange={(e) => setAccessValueRadio(e.value)}
                checked={accessValueRadio === "Read Only"}
              />
              <label style={{ paddingLeft: "10px" }} htmlFor="readOnly">
                Read Only
              </label>
            </div>
            <div className="common-share-dash">
              <RadioButton
                inputId="write"
                value="Write"
                onChange={(e) => setAccessValueRadio(e.value)}
                checked={accessValueRadio === "Write"}
              />
              <label style={{ paddingLeft: "10px" }} htmlFor="write">
                Write
              </label>
            </div>
            <div className="common-share-dash">
              <RadioButton
                inputId="noAccess"
                value="No Access"
                onChange={(e) => setAccessValueRadio(e.value)}
                checked={accessValueRadio === "No Access"}
              />
              <label style={{ paddingLeft: "10px" }} htmlFor="noAccess">
                No Access
              </label>
            </div>
            <div style={{ textAlign:'center', marginTop: "10%" }}>
              <Button
                label="Share"
                severity="success"
                icon="pi pi-check"
                autoFocus
                disabled={accessValueRadio == ''}
                onClick={shareAccessOnClick}
              />
            </div>
          </div>
        </OverlayPanel>
      </div>
    );
  }

  const confirmShareProduct = (product) => {
    setProduct(product);
    setShareProductDialog(true);
  };

  const hideShareProductDialog = () => {
    setInputFields([
      {
        fullName: "",
        fullNameDp: shareAccess && shareAccess[2].name,
      },
    ]);
    setShareProductDialog(false);
    if (isAccCompany) {
      setActiveIndex(0)
    } else {
      setActiveIndex(1)
    }

  };

  const header = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder={
              TranslationFile.dashboardSearchBtn[userSelectedLanguage]
            }
            className="dashboard-search-bar"
          />
        </span>
      </div>
    );
  };

  const options = ["My Reports", "Shared with me"];
  const [value, setValue] = useState(options[0]);

  const dashboardTabOnChange = (e) => {
    if (userSessionAuthKeyReturnValue) {
      if (null == e) {
        setValue(value);
      } else {
        setValue(e);
      }
      if (e == options[0]) {
        axios
          .get(
            "/userDashboard/getLinkSharedDetails",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respData = response.data;
            respData.map((i, idx) => {
              if (i.createdAtDateTime > i.modifiedAtDateTime) {
                return (i.modifiedAtDateTime = "");
              }
            });
            respData = respData.reverse();
            setDashboardAPIResponse(respData);
          });
      }

      if (e == options[1]) {
        axios
          .get(
            "/userDashboard/getSharedReportsForDashboard?userId=",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respData = response.data;
            respData.map((i, idx) => {
              if (i.createdAtDateTime > i.modifiedAtDateTime) {
                return (i.modifiedAtDateTime = "");
              }
            });
            respData = respData.reverse();
            setDashboardAPIResponse(respData);
          });
      }
    }
  };

  const checkForSharedReports = () => {
    axios
      .get(
        "/userDashboard/getSharedReportsForDashboard?userId=",
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        let respData = response.data;
        if (respData.length > 0) {
          dashboardTabOnChange("Shared with me");
        } else {
          dashboardTabOnChange("My Reports");
        }
      });
  };

  const dashboardMyReports = () => {
    return (
      <div className="dashboard-togglebtn">
        <SelectButton
          value={value}
          onChange={(e) => dashboardTabOnChange(e.value)}
          options={options}
          // className="my-reports-select-button"
          className="dashboardTab"
        />
      </div>
    );
  };

  const linkShareOnClick = (rowData) => {
    accRegNo = loginReducer.login.values.accountingCompanyRegNo;
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = JSON.parse(localData);
    // ---------------------------------------------------------

    setSelectedRowData(rowData);
    setInputarr([]);
    let responseObj = {
      emailId: "",
      accessLevel: "",
    };
    let arr = [];
    let lastIdx = 0;

    if (
      !loginReducer.login.values.accountingCompany ||
      !localObject.accountingCompany
    ) {
      //Private User
      if (userSessionAuthKeyReturnValue) {
        axios
          .get(
            "/userDashboard/getMyDashboard?reportId=" + rowData.reportId,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let responseData = response.data;

            responseData &&
              responseData.length &&
              responseData.map((i, idx) => {
                lastIdx = idx;
                // let accessLevelObj = {
                //   name: i.accessLevel,
                //   code: i.accessLevel,
                // };
                let responseObj = {
                  emailId: i.emailId,
                  accessLevel: i.accessLevel,
                  reportId: rowData.reportId
                };

                let { emailId, accessLevel } = i;

                responseObj.emailId = i.emailId;
                responseObj.accessLevel = i.accessLevel;
                arr.push(responseObj);

                setInputarr((inputarr) => [...inputarr, responseObj]);
              });

            setShareProductDialog(true);
          })
      }
    } else {
      //Accounting User
      if (userSessionAuthKeyReturnValue) {
        //The below API will return the accounting company details and the users/Admin under the accounting company
        axios
          .get(
            "/accountingCompany/getCompanyDetailsAndUsers?accountingCompanyId=" +
            accRegNo,
            userSessionAuthKeyReturnValue
          )
          .then((res) => {
            let resData = res.data.usersResponse;
            resData = resData.filter((val) => val.emailID != localObject.emailID && val.status != 'In Active')

            //This API will get you the access people of the report
            axios
              .get(
                "/userDashboard/getMyDashboard?reportId=" + rowData.reportId,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let responseData = response.data;
                //Step 1: Seperate the external & Internal users
                //In the internal Users, find the person who has accountingCompanyRegNo and accoutingCompanyID
                let internalUsers = responseData.filter((val) => val.accountingCompanyRegNo != null && val.accoutingCompanyID > 0 && val.accountingCompanyRegNo == accRegNo)
                //Merge the resData and internal Users
                //Need firstName, lastName, emailID from resData
                let userData = resData.map((val) => {
                  return {
                    firstName: val.firstName,
                    lastName: val.lastName,
                    emailId: val.emailID,
                  }
                })
                let mergedSet = userData.map(itemA => {
                  let matchingItemB = internalUsers.find(itemB => itemB.emailId === itemA.emailId);
                  return { ...itemA, ...matchingItemB };
                });
                mergedSet &&
                  mergedSet.length &&
                  mergedSet.map((i, idx) => {
                    lastIdx = idx;
                    // let accessLevelObj = {
                    //   name: i.accessLevel,
                    //   code: i.accessLevel,
                    // };
                    let responseObj = {
                      emailId: i.emailId,
                      accessLevel: i.accessLevel,
                      firstName: i.firstName,
                      lastName: i.lastName,
                      reportId: rowData.reportId
                    };

                    let { emailId, accessLevel } = i;

                    responseObj.emailId = i.emailId;
                    responseObj.accessLevel = i.accessLevel;
                    responseObj.firstName = i.firstName;
                    responseObj.lastName = i.lastName;
                    arr.push(responseObj);

                  });
                //Set the merged variable in companyMembers state
                setCompanyMembers(arr);

                //The accoutingCompanyID should be equal to the Report Owner, If not, the person will be considered as an external User
                //Set the external Users in inputarr state 
                let externalUsers = responseData.filter(val => !internalUsers.some(valObj => valObj.emailId == val.emailId))
                externalUsers &&
                  externalUsers.length &&
                  externalUsers.map((i, idx) => {
                    lastIdx = idx;
                    // let accessLevelObj = {
                    //   name: i.accessLevel,
                    //   code: i.accessLevel,
                    // };
                    let responseObj = {
                      emailId: i.emailId,
                      accessLevel: i.accessLevel,
                      reportId: rowData.reportId
                    };

                    let { emailId, accessLevel } = i;

                    responseObj.emailId = i.emailId;
                    responseObj.accessLevel = i.accessLevel;

                    setInputarr((inputarr) => [...inputarr, responseObj]);
                  });
              })
          });
      }
    }

    setShareProductDialog(true);
  };

  const linkSharingBodyTemplate = (rowData) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => linkShareOnClick(rowData)}
        className="linkshareTab"
      >
        {rowData.sharedToNoOfUsers > 0 ? (
          <a className="dashboard-link-share-pop-up">
            {rowData.sharedToNoOfUsers + " user"}
          </a>
        ) : (
          <Button icon="pi pi-share-alt"
          />
        )}
      </div>
    );
  };


  const companyNameBodyTemplate = (rowData) => {
    return (
      <a
        className="dashbaord-companyName"
        onClick={() => companyNameOnClick(rowData)}
      >
        {rowData.companyName}
      </a>
    );
  };

  const companyNameOnClick = (rowData) => {
    localStorage.setItem("navigateTo", "companyInformation");
    localStorage.setItem("navigateToGUID", rowData.reportGUID);
    localStorage.setItem("navigatedCompanyName", rowData.companyName);

    const annualReport = "/annualreport/";
    history.push(annualReport + rowData.reportGUID + "/companyInfo");
    window.location.reload(false);
  };

  const statusBodyTemplate = (rowData) => {
    let status = "Draft";
    if (rowData.reportStatus) {
      status = "Completed";
    }
    return (
      <Tag value={status} severity={getSeverityOrg(rowData, status)}></Tag>
    );
  };

  const paymentStatusBody = (rowData) => {
    let status;
    if (rowData.paymentEntryNo > 0) {
      status = "Paid";

      return (
        <Tag value={status} severity={getSeverityOrg(rowData, status)}></Tag>
      );
    }
  };

  const statusBodyTemplateTEST = (rowData) => {
    return getStatusLabelTEST(rowData.accountingCompanySharing);
  };

  const getStatusLabelTEST = (status) => {
    switch (status) {
      case "Read Only":
        return "Read Only";

      case "Write":
        return "Write";

      case "-":
        return "-";
    }
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <div className="TEST">
        <Dropdown
          value={options.value}
          options={statuses}
          onChange={(e) => options.filterCallback(e.value, options.index)}
          itemTemplate={statusItemTemplate}
          placeholder={TranslationFile.dashboardSelectOne[userSelectedLanguage]}
          className="dashboard-status-filter"
          showClear
          filter={false}
        />
      </div>
    );
  };

  const navigateToPdf = (reportGUID) => {
    window.open(`#/annualreport/${reportGUID}/pdf`, "_blank");
  };

  const pdfBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          // className="p-button-warning mr-2"
          rounded outlined
          tooltip="PDF"
          className="dashboard-pdf-Id"
          tooltipOptions={{ position: "bottom" }}
          // onClick={() => navigateToPdf(rowData)}
          onClick={() => previewPdf(rowData.reportGUID)}
        />
      </React.Fragment>
    );
  };

  const showCopyLinkSuccess = () => {
    toast.current.show({
      severity: "info",
      summary: TranslationFile.SuccessText[userSelectedLanguage],
      detail: TranslationFile.Linkcopied[userSelectedLanguage],
      life: 5000,
    });
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.noBtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label={TranslationFile.yesBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.noBtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label={TranslationFile.yesBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const shareProductDialogFooter = () => {
    const linkToCopy =
      config.annualReport.url +
      "/" +
      selectedRowData.reportGUID +
      "/companyInfo";

    return (
      <React.Fragment>
        <CopyToClipboard text={linkToCopy}>
          <Button
            label={TranslationFile.dashboardCopyLinkBtn[userSelectedLanguage]}
            onClick={showCopyLinkSuccess}
            outlined
            id="copy-link-button-id"
          />
        </CopyToClipboard>
      </React.Fragment>
    );
  };

  const [emailArray, setEmailArray] = useState([]);
  const sideBarStatus = (e) => {
    setSideBarOpenState(e);
  };

  const changeAccessForPeopleEmail = (e) => {
    setPeopleSelectedAccess(e.value);
  };

  const getUserShareEmail = (e) => {
    setShareEmail(e.target.value);
  };
  const changeAccessForEmail = (e) => {
    setSelectedAccess(e.value);
  };

  let localData = localStorage.getItem("rebelSkoolUser");
  let localObject = JSON.parse(localData);

  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      fullNameDp: shareAccess && shareAccess[2].name,
    };
    setInputFields([...inputFields, rowsInput]);
  };

  const addInputField = (e) => {
    if (
      inputFields[inputFields.length - 1].fullName !== "" &&
      userSessionAuthKeyReturnValue
    ) {
      let requestStr = {
        reportId: selectedRowData.reportId,
        createdByUserId: loginReducer.login.values.userID,
        userAccessLevel: inputFields[inputFields.length - 1].fullNameDp.name,
        emailId: inputFields[inputFields.length - 1].fullName,
        isInsert: true,
      };

      if (loginReducer.login.values.emailID != requestStr.emailId) {
        axios
          .post(
            "/annualReport/saveReportAccess",
            requestStr,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
              life: 3000,
            });
            axios
              .get(
                "/userDashboard/getLinkSharedDetails",
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let respData = response.data;
                respData.map((i, idx) => {
                  if (i.createdAtDateTime > i.modifiedAtDateTime) {
                    return (i.modifiedAtDateTime = "");
                  }
                });
                respData = respData.reverse();
                setDashboardAPIResponse(respData);
              });
          });
      } else {
        toast.current.show({
          severity: "error",
          summary: TranslationFile.errorText[userSelectedLanguage],
          detail: TranslationFile.CantShareReportText[userSelectedLanguage],
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setInputarr([...inputarr, { emailId, accessLevel }]);
  }, []);

  const inputEmailIDOnBlur = (e) => {
    if (inputdata.emailId != "") {
      //TODO Find Out whether the report has been shared with the emailID already
    }
  };

  function changeHandle(e) {
    setInputDate({
      ...inputdata,
      [e.target.name]: e.target.value,
    });
  }

  let { emailId, accessLevel } = inputdata;

  const addInputFieldNew = (e) => {
    if (inputdata.emailId != "" && userSessionAuthKeyReturnValue) {
      let req = {
        reportId: selectedRowData.reportId,
        createdByUserId: loginReducer.login.values.userID,
        userAccessLevel: inputdata.accessLevel.name,
        emailId: inputdata.emailId,
        isInsert: true,
      };

      if (!validateEmail(inputdata.emailId)) {
        isValidEmailIDCheck = false;
      } else {
        isValidEmailIDCheck = true;

        let objTemp = {
          emailId: inputdata.emailId,
          accessLevel: inputdata.accessLevel.name
        }
        setInputarr([...inputarr, objTemp]);

        setInputDate({ emailId: "" });

        axios
          .post(
            "/annualReport/saveReportAccess",
            req,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              toast.current.show({
                severity: "success",
                summary: TranslationFile.SuccessfulText[userSelectedLanguage],
                detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
                life: 3000,
              });

              axios
                .get(
                  "/userDashboard/getLinkSharedDetails",
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  let respData = response.data;
                  respData &&
                    respData.length &&
                    respData.map((i, idx) => {
                      if (i.createdAtDateTime > i.modifiedAtDateTime) {
                        return (i.modifiedAtDateTime = "");
                      }
                    });
                  respData = respData.reverse();
                  setDashboardAPIResponse(respData);
                });

              axios
                .get(
                  "/user/getByEmail?emailId=" + req.emailId,
                  userSessionAuthKeyReturnValue
                )
                .then((userDetailsByEmailIdResp) => {
                  userDetailsByEmailIdRespData =
                    userDetailsByEmailIdResp.data;
                  if (userDetailsByEmailIdRespData.userID > 0) {
                    let sendMailObject = {
                      recipient: inputdata.emailId,
                      emailType: "reportShare",
                      userGUID: userDetailsByEmailIdRespData.userGUID,
                      reportGuid: selectedRowData.reportGUID,
                      noOfAttempts: 0,
                      status: "New",
                      userName: localObject.emailID,
                      message: linkSharedMessage,
                    };

                    const url =
                      "/emailQueueController/insertEmailQueueAndSendMail";
                    const formData = new FormData();
                    const objString = JSON.stringify(sendMailObject);

                    formData.append("request", objString);
                    const config = {
                      headers: {
                        timeZone: userTimeZone,
                        "User-Session-Auth-Key":
                          userSessionAuthKeyReturnValue.headers[
                          "User-Session-Auth-Key"
                          ],
                      },
                    };

                    axios.post(url, formData, config).then((response) => {
                      if (response.data) {
                      }
                    });
                  }
                });
            }
          });
      }
    }
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const shareProductDialogFooterOverlap = (
    <React.Fragment>
      <Button
        label={TranslationFile.dashboardLinkShareBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="success"
        onClick={addInputFieldNew}
      />
    </React.Fragment>
  );


  const accessLevelDialogBox = () => {
    setShareEmailDialogOverlap(false);
    inputdata.emailId = "";
    setInputDate({ emailId: "" });
  };

  const [shareAccessDropdown] = useState(["Read Only", "Write", "No Access"]);


  const accessBodyTemplate = (rowData) => {
    return (
      <Tag
        style={{ textAlign: 'center' }}
        value={rowData["accessLevel"] != undefined ? rowData["accessLevel"] : 'NIL'}
        severity={getSeverityActive(rowData["accessLevel"])}
      ></Tag>
    );
  };

  const onRowEditCompleteOutside = (e) => {
    let { newData, index } = e;
    let tempOutside = [];
    let requestStr = {
      reportId: newData.reportId,
      createdByUserId: loginReducer.login.values.userID,
      userAccessLevel: newData.accessLevel,
      emailId: newData.emailId,
      isUpdate: true,
    };

    axios
      .post(
        "/annualReport/saveReportAccess",
        requestStr,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {

        if (response.data) {
          toast.current.show({
            severity: "success",
            summary: TranslationFile.SuccessfulText[userSelectedLanguage],
            detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
            life: 3000,
          });

          tempOutside = inputarr
          tempOutside && tempOutside.map((val) => {

            if (newData.emailId == val.emailId) {
              return val.accessLevel = newData.accessLevel
            }
          })
          tempOutside.length > 0 && tempOutside.map((val) => {
            setInputarr((inputarr) => [...inputarr])
          })

          //Send Email
          axios
          .get(
            "/user/getByEmail?emailId=" + newData.emailId,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let userData = response.data;
            let sendMailObject = {
            recipient: newData.emailId,
            emailType: "reportShare",
            userGUID: userData.userGUID,
            reportGuid: selectedRowData.reportGUID,
            noOfAttempts: 0,
            status: "New",
            userName: localObject.emailID,
            message: '',
          };

          const url = "/emailQueueController/insertEmailQueueAndSendMail";
          const formData = new FormData();
          const objString = JSON.stringify(sendMailObject);

          formData.append("request", objString);
          const config = {
            headers: {
              timeZone: userTimeZone,
              "User-Session-Auth-Key":
                userSessionAuthKeyReturnValue.headers[
                "User-Session-Auth-Key"
                ],
            },
          };

          axios.post(url, formData, config).then((response) => {
            if (response.data) {
             
            }
          });
          })
        }
      })
    setReRender(true)


  }
  const onRowEditComplete = (e) => {

    let { newData, index } = e;
    let temp = [];

    let requestStr = {
      reportId: newData.reportId,
      createdByUserId: loginReducer.login.values.userID,
      userAccessLevel: newData.accessLevel,
      emailId: newData.emailId,
      isUpdate: true,
    };

    axios
      .post(
        "/annualReport/saveReportAccess",
        requestStr,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {

        if (response.data) {
          toast.current.show({
            severity: "success",
            summary: TranslationFile.SuccessfulText[userSelectedLanguage],
            detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
            life: 3000,
          });
          temp = companyMembers
          temp.map((val) => {
            if (newData.emailId == val.emailId) {
              return val.accessLevel = newData.accessLevel
            }
          })
          temp && temp.map((val) => {
            setCompanyMembers((companyMembers) => [...companyMembers])
          })

//Send Email
axios
.get(
  "/user/getByEmail?emailId=" + newData.emailId,
  userSessionAuthKeyReturnValue
)
.then((response) => {
  let userData = response.data;
  let sendMailObject = {
  recipient: newData.emailId,
  emailType: "reportShare",
  userGUID: userData.userGUID,
  reportGuid: selectedRowData.reportGUID,
  noOfAttempts: 0,
  status: "New",
  userName: localObject.emailID,
  message: '',
};

const url = "/emailQueueController/insertEmailQueueAndSendMail";
const formData = new FormData();
const objString = JSON.stringify(sendMailObject);

formData.append("request", objString);
const config = {
  headers: {
    timeZone: userTimeZone,
    "User-Session-Auth-Key":
      userSessionAuthKeyReturnValue.headers[
      "User-Session-Auth-Key"
      ],
  },
};

axios.post(url, formData, config).then((response) => {
  if (response.data) {
   
  }
});
})
        }
      })
    setReRender(true)
  }

  const accessEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={shareAccessDropdown}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={options.value}
      />
    );
  };


  const callback = (annualReport, session, link, status) => {
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      history.push(annualReport + sessionUUIDFromLS + link);
    }
  };

  const navigateToNewReport = () => {
    localStorage.setItem("navigateTo", "companyInformation");
    history.push("/annualreport");
    window.location.reload(false);
  };

  const openTheLoginSidebar = () => {
    document.getElementsByClassName("user-btn")[0].click();
  };

  const emptyMessageBodyTemplate = () => {
    if (
      localObject != undefined &&
      localObject.emailID != undefined &&
      localObject.userName != undefined
    ) {
      if (value == options[0]) {
        return (
          <div>
            No Reports Found. <br></br>
            To create a New Annual Report, Click the button.
            <button
              onClick={() => navigateToNewReport()}
              id="new-report-dashboard"
              className="new-annual-report-create-button"
            >
              New Report
            </button>
          </div>
        );
      } else {
        return <div>No Shared Reports Found.</div>;
      }
    } else {
      return (
        <div>
          {value == options[0] ? (
            <div>User should be logged In to View and Manage the Reports</div>
          ) : (
            <div>
              User should be logged In to View and Manage the Shared Reports
            </div>
          )}
          <br></br>
          Click the Login Button
          <button
            onClick={() => openTheLoginSidebar()}
            id="new-report-dashboard"
            className="new-annual-report-create-button"
          >
            Login
          </button>
        </div>
      );
    }
  };

  const checkSelectedReports = (e) => {
    let paidReportArray = [];
    e &&
      e.forEach((val) => {
        if (val.paymentEntryNo > 0) {
          paidReportArray.push(val);
        } else {
          unPaidReportsArray.push(val);
        }
        unPaidReportsArray = [...new Set(unPaidReportsArray)];
        setSelectedProducts(unPaidReportsArray);
        if (paidReportArray.length > 0) {
          toast.current.show({
            severity: "error",
            summary: "No Delete Applicable",
            detail: "The Paid Report cannot be deleted",
            life: 5000,
          });
        }
      });
  };

  const sideBarCallback = () => {
    // StatusALert("signUp")
  };


  const tourStatus = (e) => {
    setRun(e)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setRun(userTourGuide);
    }, 200);
    return () => clearTimeout(timer);
  }, [userTourGuide])

  return (
    <div>

      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />


      <Toast ref={toast} position="top-right" className="toast-top" />
      <NavBarHeader isAppPortal={true} isHomePage={false} callBack={callback}
      />
      <div className="dashboard_sidebar_div">
        <Sidebar
          children="MyDashboard"
          sideBarStatus={sideBarStatus}
          sideBarCallback={() => sideBarCallback()}
          tourStatus={tourStatus}
        />
      </div>
      <div
        className={
          sideBarOpenState
            ? "myDashboardGridSideBarOpen"
            : "myDashboardGridSideBarClose"
        }
      >

        <div className="dashboardGridMainDiv">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            center={header}
            right={dashboardMyReports}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={dashboardAPIResponse}
            editMode="cell"
            selection={selectedProducts}
            onSelectionChange={(e) => checkSelectedReports(e.value)}
            selectionMode="checkbox"
            dataKey="id"
            paginator
            rows={10}
            emptyMessage={emptyMessageBodyTemplate}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[5, 10, 25, 50]}
            globalFilter={globalFilter}
            header={header}
            selectionPageOnly
            className="dashboard-datatable1"
          >
            <Column
              selectionMode="multiple"
              exportable={false}
              className="dashboard-checkbox"
            ></Column>

            <Column
              field="companyName"
              header={
                TranslationFile.dashboardCompanyName[userSelectedLanguage]
              }
              filter
              className="dashboard-company-name"
              body={companyNameBodyTemplate}
            ></Column>

            <Column
              field="registrationNo"
              header={TranslationFile.dashboardOrgNumber[userSelectedLanguage]}
              className="dashboard-org-no"
              filter
            ></Column>
            <Column
              field="financialYear"
              header={TranslationFile.dashboardFinYear[userSelectedLanguage]}
              filter
              className="dashboard-financial-year"
            ></Column>

            {value == "My Reports" && (
              <Column
                field="linkSharing"
                header={
                  TranslationFile.dashboardLinkShare[userSelectedLanguage]
                }
                className="dashboard-link-sharing"
                filter
                filterPlaceholder="Search"
                body={linkSharingBodyTemplate}
              ></Column>
            )}

            <Column
              field="createdAtDateTime"
              header={TranslationFile.dashboardCreatedOn[userSelectedLanguage]}
              filter
              className="dashboard-created-on"
            ></Column>

            <Column
              field="createdBy"
              header={TranslationFile.dashboardCreatedBy[userSelectedLanguage]}
              className="dashboard-created-by"
              filter
            ></Column>
            {value == options[0] && (
              <Column
                // field="createdBy"
                header="Payment Status"
                className="dashboard-created-by"
                body={paymentStatusBody}
                filter
              ></Column>
            )}

            <Column
              field="modifiedAtDateTime"
              header={TranslationFile.dashboardModifiedOn[userSelectedLanguage]}
              filter
              // filterPlaceholder="YYYY-MM-DD"
              className="dashboard-modified-on"
            ></Column>
            <Column
              field="modifiedBy"
              header={TranslationFile.dashboardModifiedBy[userSelectedLanguage]}
              className="dashboard-modified-by"
              filter
            ></Column>

            <Column
              field="reportStatus"
              header={TranslationFile.dashboardStatusBtn[userSelectedLanguage]}
              body={statusBodyTemplate}
              filter
              filterElement={statusFilterTemplate}
              className="dashboard-status"
              showFilterMatchModes={false}
            ></Column>

            <Column
              body={pdfBodyTemplate}
              exportable={false}
              className="dashboard-pdf"
              header="PDF"
              title="Download PDF"
            ></Column>
          </DataTable>
        </div>
        {/* )} */}
      </div>

      <Dialog
        visible={deleteProductDialog}
        blockScroll
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={TranslationFile.dashboardConfirmBtn[userSelectedLanguage]}
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <span>
            <span>
              {" "}
              {TranslationFile.dashboardDeleteText[userSelectedLanguage]}
            </span>
          </span>
        </div>
      </Dialog>

      <Dialog
        blockScroll
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={TranslationFile.dashboardConfirmBtn[userSelectedLanguage]}
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <div>
            <span>
              {TranslationFile.dashboardDeleteText[userSelectedLanguage]}
            </span>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Share Your Annual Report"
        blockScroll
        visible={shareProductDialog}
        style={{
          width: "60vw",
          height: "36vw",
        }}
        footer={shareProductDialogFooter}
        onHide={() => hideShareProductDialog()}
      >
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="Annual_reportBox1"
        >
          {isAccCompany && (
            <TabPanel header="Inside My Organization" className="">
              <div>
                {companyMembers && companyMembers.length > 0 ? (
                  <div className="reportShareDetails">
                    <div className="share_people_box">
                      <Toolbar
                        // className="admin-toolbar"
                        left={leftToolbarTemplatebox2}
                      ></Toolbar>
                      <DataTable
                        editMode="row"
                        paginator
                        rows={10}
                        emptyMessage="No Users Added"
                        onRowEditComplete={onRowEditComplete}
                        value={companyMembers}
                      >

                        <Column
                          header="First Name"
                          field="firstName"
                          showFilterMatchModes={false}
                          disabled
                        >
                        </Column>
                        <Column
                          header="Last Name"
                          field="lastName"
                          showFilterMatchModes={false}
                          disabled

                        >
                        </Column>

                        <Column
                          header="Email ID"
                          field="emailId"
                          showFilterMatchModes={false}
                          disabled
                        ></Column>

                        <Column
                          header="Access"
                          field="accessLevel"
                          showFilterMatchModes={false}
                          body={accessBodyTemplate}
                          editor={(options) => accessEditor(options)}
                        ></Column>
                        <Column
                          rowEditor
                        ></Column>

                      </DataTable>
                    </div>
                  </div>

                ) : (
                  <div className="dash-inside-organization">
                    No people in your Organization
                  </div>
                )}
              </div>
            </TabPanel>

          )}

          <TabPanel
            header={isAccCompany ? "Outside My Organization" : "Report Sharing"}
          >
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}
            >
              <Card title="Share Your Annual Report">
                <div className="share_mail_box">
                  <Row className="share-overlap-row">
                    <Col className="link-email-input-box">
                      <InputText
                        type="text"
                        name="emailId"
                        value={inputdata.emailId}
                        onChange={changeHandle}
                        onBlur={inputEmailIDOnBlur}
                        placeholder={
                          TranslationFile.dashboardEnterEmailId[
                          userSelectedLanguage
                          ]
                        }
                        className="emai-id-overlap"
                      />
                    </Col>

                    <Col className="link-access-dropdown">
                      <Dropdown
                        value={inputdata.accessLevel}
                        options={shareAccess}
                        optionLabel="name"
                        name="accessLevel"
                        className="mail-give-access-dropdown"
                        onChange={changeHandle}
                        dropdownIcon={
                          <RiArrowDownSFill className="drop-down-black-fill-icon" />
                        }
                      />
                    </Col>
                  </Row>
                  <br></br>

                  <div className="share-message">
                    {" "}
                    {
                      TranslationFile.dashboardLinkShareMessage[
                      userSelectedLanguage
                      ]
                    }
                  </div>
                  <InputTextarea
                    rows={4}
                    cols={30}
                    onChange={(e) => setLinkSharedMessage(e.target.value)}
                    className="share-annual-text-area"
                  />
                  <center className="dashboard-share-btn">
                    <Button
                      label="Share"
                      severity="success"
                      icon="pi pi-check"
                      onClick={addInputFieldNew}
                    />
                  </center>
                </div>
              </Card>
              <Card
                title="People with Access"
                className="dashboard-linksharing-card"
              >
                <div className="people-access-card">
                  <DataTable
                    paginator
                    editMode="row"
                    rows={10}
                    value={inputarr}
                    emptyMessage="No Users Added"
                    onRowEditComplete={onRowEditCompleteOutside}
                  >
                    <Column
                      header="Email ID"
                      field="emailId"
                      showFilterMatchModes={false}
                    ></Column>
                    <Column
                      header="Access"
                      field="accessLevel"
                      showFilterMatchModes={false}
                      body={accessBodyTemplate}
                      editor={(options) => accessEditor(options)}
                    ></Column>

                    <Column
                      rowEditor
                    ></Column>

                  </DataTable>
                </div>
              </Card>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
      <ScrolltoTop />
      <Footerpage />
      <Footer_contact_tab isCorpPortal={false} isHomePage={true} />
    </div>
  );
}
