import React from "react";
import { Link } from "react-router-dom";
import Footer_contact_tab from "./Footer_contact_tab";

export default class Footerpage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <div>
    {/* <Footer_contact_tab  isCorpPortal={true} isHomePage={true}/> */}
    </div>
   ) }

    }
