import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { FiTrash2 } from "react-icons/fi";
import { connect } from "react-redux";
import PropTypes from 'prop-types'


const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices:state.prices.prices
  };
};

let cartState = [];
class Cart extends Component {
  static propTypes = {
    cart: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
 }
 
 static defaultProps = {
    cart: [],
    show: true,
    closeButton: true,
    autoFocus: true,
    backdrop: false,
    scrollable: false
  }
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentWillMount() {
    // const {prices} = this.props
    // cartState = prices.values;
    this.handlePrice();
  }

   handlePrice = () => {
    
    let ans = 0;
    cartState && cartState.map((item)=>(
        ans += item.amount * item.price
    ))
  };

   handleChange = (item, d) =>{

	}
  

  render() {
    const {cart} = this.props
   
    return (
      <div>
        <div>
           <article>
            <div className="scrollpanel-demo">
              <div>
                <ScrollPanel
                  style={{ height: "500px" }}
                  className="custombar1"
                >
                  {cartState &&
                    cartState.map((item) => (
                      <div className="cart-first">
                        <div className="cart_box" key={item.id}>
                          <ul
                            className="cart_box_ul"
                            style={{ listStyle: "none" }}
                          >
                            <li className="cart_box_li">
                              <a>
                                <div
                                  className="cart-div1-drop"
                                  style={{ display: "flex" }}
                                >
                                  <div className="cart-col-1">
                                    {item.title}
                                    <br></br>
                                    {item.desc1}
                                    <br></br>
                                    SEK {item.price}.
                                  </div>

                                  <div className="cart-col-4">
                                    <Button
                                      icon="pi pi-trash"
                                      style={{ color: "black" }}
                                      // rounded
                                      text
                                      aria-label="Cancel"
                                      className="cart-col-4"
                                      // onClick={() => handleRemove(id)}
                                    />
                                  </div>
                                </div>

                                <div
                                  className="cart-div2-drop"
                                  style={{ display: "flex" }}
                                >
                                  <div className="cart-col-2">
                                    <span>
                                      <Button
                                        icon="pi pi-minus"
                                        text
                                        outlined
                                        aria-label="Cancel"
                                        onClick={() => this.handleChange(item, -1)}
                                      />
                                    </span>
                                    <span>
                                      <InputNumber
                                        style={{ width: "2em", height: "2em" }}
                                        value={item.amount}
                                        className="cart-item-amount22"
                                      />
                                    </span>
                                    <span>
                                      <Button
                                        icon="pi pi-plus"
                                        text
                                        aria-label="Cancel"
                                        className="cart-plus-button"
                                        onClick={() => this.handleChange(item, +1)}
                                      />
                                    </span>
                                  </div>

                                  <div className="cart-col-3">
                                    <p> SEK {item.price * item.amount}</p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                          <Divider className="cart_divider" />
                        </div>
                      </div>
                    ))}
                </ScrollPanel>
              </div>
            </div>

            {cartState.length > 0 ? (
              <div className="cart-total-price-div">
                <div className="cart-totalprice">
                  <div className="cart-total-text">Total Price</div>
                  {/* <div className="cart-total-end-price"> SEK {price}</div> */}
                </div>

                <div className="cart-checkout">
                  <Button
                    label="Go To Checkout"
                    rounded
                    className="cart-checkout-btn22"
                  />
                </div>
              </div>
            ) : (
              ""
              // <div className="cart-homejs-display">Your Cart is empty</div>
            )}
          </article> 
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Cart);