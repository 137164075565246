import React, { Component } from "react";
import Config from "../../Configuration/Config"
import "./NoAccess.css";

class NoAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }


  render() {
    return (
      <div className="Error-Main-Div">
        <div className="Error_401">
          {/* <h1 className="Error_text">401</h1>

          <h3 className="Error_status"> Unauthorized</h3> */}
          <div className="Error_heading">
            You don't have permission to access this Report
          </div>
          <a className="Error_back-home" href={Config.annualReport.url}>
            Back to home
          </a>
        </div>
      </div>
    );
  }
}

export default NoAccess;
