import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import axios from "axios";
import { Messages } from "primereact/messages";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import "./ReviewAndSend.css";

// import Header from "../Menu/Header";
// import { Dialog } from "primereact/dialog";
// import { Message } from "primereact/message";
// import { ProgressSpinner } from "primereact/progressspinner";
// import Footerpage from "../Footerpage/Footerpage";


let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails.sessionDetails.values,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    payment: state.payment.payment,
  };
};
let base64 = ``;
let currentReportGUIDFromUrl = "",
  linkSharedUser,
  sideBarGreenTickPages = [];
let userSelectedLanguage;

class ReviewAndSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutId: "",
      checkoutPurchaseId: "",
      checkoutAmount: 0,
      showPayementSuccessModal: false,
      showNotification: false,
      loading: true,
      pdfUrl: "",
      sideBarStatus: true,
    };
    this.acceptSendToBolagsverket = this.acceptSendToBolagsverket.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    const { login, sideBarReducer, payment } = this.props;

    // base64 = localStorage.getItem("pdfBase64");

    let paysonGetCheckoutObj = {
      userId: login.userID,
      isCheckoutObj: true,
    };

    this.setState({
      showNotification: false,
    });
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    window.scrollTo(0, 0);

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseDate = response.data;
            // let localData = localStorage.getItem("rebelSkoolUser");
            // let localObject = "";
            // if (localData != "undefined") {
            //   localObject = JSON.parse(localData);

            if (responseDate != "") {
              let reportAccessReq = {
                reportId: responseDate.reportID,
              };
              axios
                .post(
                  "/annualReport/getAccessStatus",
                  reportAccessReq,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  switch (response.data) {
                    case 2:
                      linkSharedUser = 2; //Write
                      break;
                    case 3:
                      linkSharedUser = 3; //Read Only
                      break;
                    case 4:
                      linkSharedUser = 4; //No Access
                      break;
                  }

                  if (response.data <= 3) {
                    axios
                      .get(
                        "/annualReport/pdf?reportGUID=" +
                        currentReportGUIDFromUrl,
                        {
                          responseType: "blob",
                        }
                      )
                      .then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data], { type: "application/pdf" })
                        );
                        this.setState({
                          pdfUrl: url,
                        });
                      });
                  }

                  axios
                    .get(
                      "/annualReport/getCompletedPages?reportId=" +
                      responseDate.reportID,
                      userSessionAuthKeyReturnValue
                    )
                    .then((completedStatementTypesResponse) => {
                      sideBarReducer.values =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages.push(
                        "My Dashboard",
                        "Company Information",
                        "Review And Send"
                      );
                      let updatePossibleNavigationPagesListString =
                        JSON.stringify(sideBarGreenTickPages);
                      localStorage.setItem(
                        "possibleAccessablePages",
                        updatePossibleNavigationPagesListString
                      );
                      this.setState({
                        reRender: true,
                      });
                    });
                })
                .catch((error) => {
                  let noAccessURL =
                    "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                  this.props.history.push(noAccessURL);
                });
            }
            // }

            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                let sideBarReducerValues = [];
                sideBarReducerValues = completedStatementTypesResponse.data;
                if (linkSharedUser != 3) {
                  sideBarReducerValues.push("Payment");
                }
                sideBarReducer.values = sideBarReducerValues;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });

                setTimeout(() => {
                  if (payment.values.checkoutRef != "") {
                    axios
                      .get(
                        "/annualReportPackage/getCheckoutDetailsByRef?checkoutRef=" +
                        payment.values.checkoutRef,
                        userSessionAuthKeyReturnValue
                      )
                      .then((checkoutRefResponse) => {
                        this.setState({
                          loading: false,
                        });
                        if (
                          checkoutRefResponse.data != "" &&
                          !checkoutRefResponse.data.isNotificationShown
                        ) {
                          this.setState({
                            showNotification: true,
                          });
                          axios
                            .get(
                              "/annualReportPackage/updateNotificationByCheckoutRef?checkoutRef=" +
                              payment.values.checkoutRef,
                              userSessionAuthKeyReturnValue
                            )
                            .then((updateNotifyResponse) => { });
                        }
                      });
                  } else {
                    this.setState({
                      loading: false,
                    });
                  }
                }, 3000);
              });
          }
        });
    }
  }

  componentDidMount() {
    this.msgs1.show([
      {
        severity: "success",
        detail: TranslationFile.Paymentsuccess[userSelectedLanguage],
        sticky: true,
      },
    ]);
  }
  paymentSuccessModalOnHide() {
    this.setState({
      showPayementSuccessModal: false,
    });
  }

  acceptSendToBolagsverket() {
    // Functionality to send to Bolagsverket
    // After sending the report to Bolagsverket, the submission count in the Annual Report Header table must be reduced to 2
    // If the submission count is 1, we have to alert the user that there is no more attempts to send the report

    this.toast.show({
      severity: "success",
      summary: TranslationFile.SumissionSuccess[userSelectedLanguage],
      detail: TranslationFile.PaymentSubmitToBolags[userSelectedLanguage],
      life: 2000,
    });
  }

  next(e) {
    const annualReport = "/annualreport/";
    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/digitalSignature"
    );
  }

  confirmSubmitToBolagsverket(e) {
    confirmPopup({
      target: e.currentTarget,
      message: "Are you sure you want to send this report to Bolagsverket",
      icon: "pi pi-check",
      accept: this.acceptSendToBolagsverket.bind(this),
      // reject
    });
  }

  editReport() {
    const annualReport = "/annualreport/";
    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/companyInfo"
    );
    window.location.reload(false);
  }

  sideBarCallback = (value) => { };
  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };
  render() {
    const {
      checkoutId,
      checkoutPurchaseId,
      checkoutAmount,
      showPayementSuccessModal,
      showNotification,
      loading,
      pdfUrl,
    } = this.state;

    return (
      <div className="notes-main-div">
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "80px" }}
        ></Toast>

        {/* <Header /> */}
        <NavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />

        <div className="paymentSideBar">
          <Sidebar
            children="Review And Send"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={(e) => this.sideBarCallback(e)}
          />
        </div>
        <ConfirmPopup />
        <div
          className="reviewAndSendPaymentSuccess"
          style={{ display: showNotification ? "inherit" : "none" }}
        >
          <Messages ref={(el) => (this.msgs1 = el)} />
        </div>

        <div className="reviewAndSend-div">
          <div className="review-edit">
            <Row>
              <Col xl={10} style={{ textAlign: "center", paddingTop: "3px" }}>
                <h5>
                  Kindly Review the PDF and send the report to Bolagsverket
                </h5>
              </Col>
              <Col>
                <Button
                  label="Edit Report"
                  icon="pi pi-pencil"
                  onClick={() => this.editReport()}
                />
              </Col>
            </Row>
          </div>
          <center>
            <div>
              {pdfUrl && pdfUrl.length > 0 && (
                <iframe src={pdfUrl} width="1150px" height="1130px"></iframe>
              )}
            </div>
            <div className="review-submit">
              <Button
                label="Submit to Bolagsverket"
                icon="pi pi-send"
                onClick={(e) => this.confirmSubmitToBolagsverket(e)}
                disabled={linkSharedUser == 2 || linkSharedUser == 3}
              />
            </div>
          </center>
        </div>

        <ScrolltoTop />
        {/* <Footerpage /> */}
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(ReviewAndSend);
