const serverTomcatConfig = {
  statusBar: {
    visibility: true,
    messgae: "UnderDevelopmentStatusBand",
    color: "red",
  },
  backend: {
    url: "https://testapi.rebelskool.com/",
  },
  apps: {
    url: "http://testapps.rebelskool.com/",
  },
  annualReport: {
    url: "http://testapps.rebelskool.com/annualreport",
  },
  annualReportWithHash: {
    url: "http://testapps.rebelskool.com/#/annualreport",
  },
  corporatePage: {
    url: "http://test.rebelskool.com/",
  },
  SRU: {
    url: "http://testapps.rebelskool.com/income-declaration-2",
  },
  NE: {
    url: "http://testapps.rebelskool.com/income-declaration-1",
  },
  forAccountingCompanies: {
    url: "http://testapps.rebelskool.com/for-accounting-companies",
  },
  microsoftBusinessCentral: {
    url: "http://testapps.rebelskool.com/microsoft-business-central-development",
  },
  ContactUs: {
    uploadFileSizeLimit: 2,
  },
};

export default serverTomcatConfig;
