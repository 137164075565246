import React, { Component } from "react";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footerpage from "../Footerpage/Footerpage";
import TranslationFile from "../15-Translation/locale/Translation.json";
import "./AboutUs.css";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";

let userSelectedLanguage;

class PricePolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <NavBarHeader isCorpPortal={true} />
        <div className="ID_About-Us">
          <br></br>
          <br></br>
          <div class="cor-product" id="price-policy-div">
            <div id="cor-product-section">
              <h2 className="cor-product-h2">
                {TranslationFile.NavbarPricePolicy[userSelectedLanguage]}
              </h2>
              <p id="cor-product-paragraph">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
        <ScrolltoTop />
        {/* <Footerpage /> */}
        <Footer_contact_tab sideBar={this.state.sideBarStatus} isCorpPortal={false} isHomePage={true}/>

      </div>
    );
  }
}
export default PricePolicy;
