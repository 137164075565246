import React, { useState } from "react";
import "./OtherServices.css";
import ServiceAR from "../../Assests/ServiceAR.jpg";
import ServiceSRU from "../../Assests/ServiceSRU.jpg";
import ServiceNE from "../../Assests/ServiceNE (1).png";
import { Button } from "primereact/button";
import TranslationFile from "../15-Translation/locale/Translation.json";

let userSelectedLanguage;
const servicesList = [
  {
    image: ServiceAR,
    title: "Annual Report",
    description:
      "For those who only need to draw up an annual report (K2) and submit a digital copy to the Swedish Companies Registration Office. Fast, simple and affordable",
    button: "Begin",
  },
  {
    image: ServiceSRU,
    title: "Income Declaration - SRU",
    description:
      "Do you lack programs to declare digitally? Through our online service, you can easily create SRU files, which are the Swedish Tax Agency's format for digital forms.",
    button: "Coming Fall 2024",
  },
  {
    image: ServiceNE,
    title: "Income Declaration - NE",
    description:
      "Do you lack programs to declare digitally? Through our online service, you can easily create NE files, which are the Swedish Tax Agency's format for digital forms.",
    button: "Coming Fall 2024",
  },
];

const OtherServices = () => {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const [testState, setTestState] = useState(false);

  const test = () => {
    setTestState(true);
  };

  return (
    <div className="Otherservices-main-div">
      <div className="Otherservices-main-title">
        <span className="Otherservices-span-main">
          {/* OUR SERVICES */}
          <h1> {TranslationFile.serviceTitle1[userSelectedLanguage]}</h1>
        </span>
        {/* Find out a little More About US */}
        {TranslationFile.serviceTitle2[userSelectedLanguage]}
      </div>
      <div className="other-services-row">
        {servicesList.map((service, index) => (
          <div className="other-services-col">
            <div className="other-services-table">
              <div className="service-image">
                <img src={service.image} className="service-image"></img>
              </div>
              <div className="services-text">{service.title}</div>
              <br></br>
              <div className="services-text-description">
                <p className="services-text-description-p">
                  {service.description}
                </p>
              </div>
              <Button
                // label={TranslationFile.serviceBtn[userSelectedLanguage]}
                label={service.button}
                severity="info"
                className="services-begin-button"
                id="btn_id_other_services"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherServices;
