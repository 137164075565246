import React, { Component } from "react";
import image1 from "../../../Assests/image1.png";
import image2 from "../../../Assests/image2.png";
import image3 from "../../../Assests/image3.png";

const SRUSlideList = [
  {
    id: 1,
    img: image1,
  },
  {
    id: 2,
    img: image2,
  },
  {
    id: 3,
    img: image3,
  },
];

export default SRUSlideList;
