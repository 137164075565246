import React from "react";
import Dropdown from "./Dropdown";
import { useState, useEffect, useRef } from "react";
// import {HashLink as Link} from 'react-router-hash-link'
import { Link } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useHistory } from "react-router-dom";
import TranslationFile from "../15-Translation/locale/Translation.json";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
  };
};

let menuScrollValue = {};
let navigateTo = "";
let userSelectedLanguage;
const MenuItem = ({ items, depthLevel }) => {
  const history = useHistory();

  const [dropdown, setDropdown] = useState(false);
  const [scrollClass, setScrollClass] = useState("");
  const [render, setRender] = useState(false);
  let ref = useRef();
  const currentPageNameReducer = useSelector(
    (state) => state.currentPageName.currentPageName.values
  );
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  
  useEffect(() => {
    let navigateToClassName = localStorage.getItem("navigateToClassName");

    if (currentPageNameReducer == "Home" && navigateToClassName != null) {
      setTimeout(() => {
        document.getElementById(navigateToClassName).click();
        localStorage.removeItem("navigateToClassName");
        setRender(true);
      }, 500);
    }

    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);
  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const nav = (items) => {
    if (currentPageNameReducer != "Home") {
      localStorage.setItem(
        "navigateToClassName",
        items.className + "Navigation"
      );
      history.push("/annualreport");
    }
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <div>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {/* {items.title} */}
            {userSelectedLanguage=="sw"
            ? items.swTitle : items.title}

            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>

          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
            className="drop"
          />
        </div>
      ) : (
        <Link
          className="menu-items-link"
          // to={scrollClass != '' ? scrollClass : items.className}
          to={items.className}
          spy={true}
          smooth={true}
          offset={items.offset}
          duration={50}
          onClick={() => nav(items)}
          id={items.className + "Navigation"}
        >
          {/* {items.title} */}
          {userSelectedLanguage=="sw"
            ? items.swTitle : items.title}
        </Link>
      )}
    </li>
  );
};

export default MenuItem;
