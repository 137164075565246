import React from "react";
import MenuItem from "./MenuItem";
import { Link } from "react-scroll";

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <div>
{window.innerWidth > 960 &&
(
  <ul 
  className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}
  >
    {submenus.map((submenu, index) => (
      <MenuItem items={submenu} key={index} depthLevel={depthLevel} />
    ))}
  </ul>
)}
{window.innerWidth < 960 && (
 <ul 
//  className="dropdown-try"
//  className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}
 >
  {submenus.map((submenu, index) => (
    <Link
    className="menu-items-link"
    to={submenu.className}
    spy={true}
    smooth={true}
    offset={-15}
    duration={50}
  >
    {submenu.title} 
  </Link>
      // <MenuItem items={submenu} key={index} depthLevel={depthLevel} />
    ))}

   
 </ul>
)}
    </div>

    
  );
};
  
  export default Dropdown;