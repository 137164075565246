import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import BankIdLogo from "../../Assests/BankID_logo.png";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

import Sidebar from "../Sidebar/Sidebar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import { useHistory } from "react-router-dom";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import axios from "axios";

import "./digitalSignature.css";

let userSelectedLanguage;
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let currentReportGUIDFromUrl = "";
export default function Representative() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const history = useHistory();

  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [digitalignatureReps, setDigitalignatureReps] = useState([]);
  const [visibleQrCodeDialogBox, setVisibleQrCodeDialogBox] = useState(false);
  const [reportId, setReportId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const toast = useRef(null);

  const sideBarReducer = useSelector(
    (state) => state.sideBarReducer.sideBarReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((getHeaderResponse) => {
          if (getHeaderResponse.data) {
            setReportId(getHeaderResponse.data.reportID);
            setCompanyName(getHeaderResponse.data.companyName);
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                  getHeaderResponse.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((getRepresentativeResponse) => {
                let representativeResponseList = getRepresentativeResponse.data;
                if (
                  representativeResponseList != null &&
                  representativeResponseList != "" &&
                  representativeResponseList.length > 0
                ) {
                  let digitalSignatureRepsList = [];
                  digitalSignatureRepsList = representativeResponseList.filter(
                    (obj) => obj.signType == 1
                  );

                  setDigitalignatureReps(digitalSignatureRepsList);
                }
              });
          }
        });
    }
  }, []);

  const sideBarStatusFnc = (e) => {
    setSideBarStatus(e);
  };

  const sideBarCallback = (value) => {
    if (value) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const digitalSignBtn = (product) => {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      product.companyName = companyName;
      product.language = userSelectedLanguage;
      axios
        .post(
          "/annualReportRepresentative/doDigitalSignature",
          product,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data.reportID > 0) {
            setVisibleQrCodeDialogBox(true);
          }
        });
    }
  };

  const digitalSignatureStatusTemplate = (product) => {
    if (product.signStatus) {
      return (
        <div>
          <Button
            label="Signed"
            severity="success"
            rounded
            className="dseBtn"
            disabled
          />
        </div>
      );
    } else {
      return (
        <Button
          label="Pending"
          className="dseBtn"
          rounded
          onClick={() => digitalSignBtn(product)}
        />
      );
    }
  };

  const signatureDialogBoxOnHide = () => {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReportRepresentative/getByReportId?reportID=" + reportId,
          userSessionAuthKeyReturnValue
        )
        .then((getRepresentativeResponse) => {
          let representativeResponseList = getRepresentativeResponse.data;
          if (
            representativeResponseList != null &&
            representativeResponseList != "" &&
            representativeResponseList.length > 0
          ) {
            let digitalSignatureRepsList = [];
            digitalSignatureRepsList = representativeResponseList.filter(
              (obj) => obj.signType == 1
            );

            setDigitalignatureReps(digitalSignatureRepsList);
          }
        });
    }

    setVisibleQrCodeDialogBox(false);
  };

  const navigateToReviewAndSend = () => {
    history.push("reviewAndSend");
  };

  return (
    <div>
      <NavBarHeader isAppPortal={true} isHomePage={false} />
      <div className="representative-sidebar-div">
        <Sidebar
          children="Digital Signature"
          sideBarStatus={(e) => sideBarStatusFnc(e)}
          sideBarCallback={(e) => sideBarCallback(e)}
        />
      </div>

      <div
        className={
          sideBarStatus
            ? "digitalSignature-div"
            : "digitalSignature-div-sidebar"
        }
      >
        <div className="digitalSignPdfDiv">
          <Button
            type="button"
            icon="pi pi-file-pdf"
            outlined
            style={{ borderRadius: "70px" }}
            className="pdf_view_rep"
            tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
            tooltipOptions={{ position: "bottom" }}
            onClick={() => previewPdf(currentReportGUIDFromUrl)}
          />
        </div>

        <Row style={{ width: "100%" }}>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ width: "100%", marginLeft: "12px" }}
          >
            <center>
              <DataTable
                value={digitalignatureReps}
                className="digitalSignatureDataTable"
              >
                <Column
                  field="firstName"
                  header="First Name"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="lastName"
                  header="Last Name"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="role"
                  header="Role"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="ssn"
                  header="SSN"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  header="Digital Signature Status"
                  body={(e) => digitalSignatureStatusTemplate(e)}
                ></Column>
              </DataTable>
              <Dialog
                header="Digital Signature via BankID"
                visible={visibleQrCodeDialogBox}
          blockScroll
          style={{ width: "80vw", height: "100vw" }}
                onHide={() => {
                  signatureDialogBoxOnHide();
                }}
              >
                <div className="bankIdIframe">
                  <center>
                    <iframe
                      src="https://127.0.0.1:5173/"
                      style={{ width: "90%", height: "40em", border: "none" }}
                    />
                  </center>
                </div>
              </Dialog>
            </center>
          </Col>
        </Row>
      </div>
      <center>
        <Button
          label="Review & Send"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => navigateToReviewAndSend()}
        />
      </center>
    </div>
  );
}
