import React from "react";
import Contact from "../Contact Us/Contact";
import TranslationFile from "../15-Translation/locale/Translation.json";

export const menuItems =  [
  {
    swTitle: "Online tjänster",
    title: "Online Services",
    url: "/",
    className: "Otherservices-main-div",
    offset: -15,
    submenu: [
      {
        swTitle: "Årlig rapport",
        title: "Annual Report",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
      },
      {
        swTitle: "Inkomstdeklaration",
        title: "Income Declaration",
        url: "/",
        className: "Otherservices-main-div",
        offset: -10,
        submenu: [
          {
            swTitle: "SRU-filer",
            title: "SRU Files",
            url: "/ourservices",
            className: "Otherservices-main-div",
            offset: -10,
          },
          {
            swTitle: "NE bilaga",
            title: "NE appendix",
            url: "/ourservices",
            className: "Otherservices-main-div",
            offset: -10,
          },
        ],
      },
      {
        swTitle: "För redovisningsföretag",
        title: "For Accounting Companies",
        url: "/",
        className: "Otherservices-main-div",
        offset: -15,
      },
      {
        swTitle: "Microsoft Business Central",
        title: "Microsoft Business Central",
        url: "/",
        className: "Otherservices-main-div",
        offset: -15,
      },
    ],
  },
  {
    swTitle: "priser",
    title: "Prices",
    url: "prices",
    className: "prices-main-title-div",
    offset: -15,
    submenu: [
      {
        swTitle: "Årlig rapport",
        title: "Annual Report",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
      },
      {
        swTitle: "Inkomstdeklaration",
        title: "Income Declaration",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
      },
    ],
  },
  {
    swTitle: "FAQ / Referenser",
    title: "FAQ / References",
    url: "/",
    className: "prices-main-title-div",
    offset: -15,
    submenu: [
      {
        swTitle: "Årlig rapport",
        title: "Annual Report",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
        submenu: [
          {
            swTitle: "Hur det fungerar",
            title: "How it works",
            url: "/ourservices",
            className: "Otherservices-main-div",
            offset: -10,
          },
          {
            swTitle: "FAQ",
            title: "FAQ",
            url: "/ourservices",
            className: "Otherservices-main-div",
            offset: -10,
          },
        ],
      },
      {
        swTitle: "Datasäkerhet",
        title: "Data Security",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
      },
      {
        swTitle: "Referenser",
        title: "References",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
      },
      {
        swTitle: "För investerare",
        title: "For Investors",
        url: "/annualreport",
        className: "Otherservices-main-div",
        offset: -10,
      },
    ],
  },
  {
    swTitle: "kontakta oss",
    title: "About Us",
    url: "/corpContactUs",
    className: "corp-container",
    offset: -15,
    submenu: [
      {
        swTitle: "Varför RebelSkool?",
        title: "why RebelSkool?",
        url: "/",
        className: "Otherservices-main-div",
        offset: -10,
      },
      {
        swTitle: "Prispolicy",
        title: "Price Policy",
        url: "/",
        className: "Otherservices-main-div",
        offset: -10,
      },

    ],
  },
  {
    swTitle: "kontakta oss",
    title: "Contact Us",
    url: "/corpContactUs",
    className: "corp-container",
    offset: 0,
  },

];
