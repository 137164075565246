import React, { Component } from "react";
import TranslationFile from "../../15-Translation/locale/Translation.json";
import { Toast } from "primereact/toast";
import Sidebar from "../SRUSidebar/SRUSidebar";
import Footer_contact_tab from "../../Footerpage/Footer_contact_tab";
import ScrolltoTop from "../../ScrollTop/ScrollTop";
import SRUNavBarHeader from "../SRUNavBarHeader/SRUNavBarHeader";
import { setUserSessionAuthKey } from "../../../Component/commonUtils";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import "./INK2S.css";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { FcInfo } from "react-icons/fc";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    activeSideBar: state.activeSideBar.activeSideBar,
    incomeStatement: state.incomeStatement.incomeStatement,
    currentPageName: state.currentPageName.currentPageName,
    readOnly: state.readOnly.readOnly,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let userSelectedLanguage;
let tabTitleArray = [],
  sruTemplateResponse = {},
  sruTemplateReqArray = [];
let sruReportHeaderRequestObj = {
  reportGUID: "",
  companyName: "",
  registrationNo: "",
  postalCode: 0,
  city: "",
  currentYear: "",
},
  currentReportGUIDFromUrl,
  selectedForms = [],
  currentPageName = "INK2S";

class INK2S extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.inputNumberRef = [];
    this.state = {
      activeIndex: [0],
      checked: true,
      checkedNobtn: true,
      checkedYesbtn: false,
      checkedNobtn2: true,
      checkedYesbtn2: false,
      sideBarStatus: true,
      latestCustomLineNo: 0,
      reportGUID: "",
      finalSumLbl: "Årets resultat",
      toggleOpen: false,
      headerCount: 0,
      isLoading: true,
      sruValue: "",
      companyName: "",
      currentFinancialYear: "",
      clickedIndex: null,
      clickedParentIndex: null,
      columnIndex: null,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }
  handleClickOutside(event) {
    let index = this.state.clickedIndex;
    let inputReference = this.inputNumberRef[index];
    if (
      this.state.clickedIndex !== null &&
      inputReference
    ) {
      this.setState({ clickedIndex: null });
    }
  }
  setClickedIndex(idx, parentIdx, number) {
    this.setState(prevState => ({
      clickedIndex: prevState.clickedIndex === idx ? null : idx
    }));
    this.setState({
      // clickedIndex: idx,
      clickedParentIndex: parentIdx,
      columnIndex: number,
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
componentWillUnmount() {
document.removeEventListener('mousedown', this.handleClickOutside);
}
  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let headerIdxList = [];
      Object.keys(tabTitleArray).map((heading, idx) => {
        headerIdxList.push(idx);
      });
      this.setState({ activeIndex: headerIdxList });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }

  openAll(resp) {
    let headerIdxList = [];
    Object.keys(resp).map((heading, idx) => {
      headerIdxList.push(idx);
    });
    let open = headerIdxList;
    this.setState({ activeIndex: open });
  }

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });
    
  setRef = (index) => (el) => {
    this.inputNumberRef[index] = el;
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

    sruReportHeaderRequestObj.reportGUID = currentReportGUIDFromUrl;
    if (userSessionAuthKeyReturnValue) {
      sruTemplateReqArray = [];
      axios
        .get(
          "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data.forms != "" && response.data.forms != null) {
            selectedForms = response.data.forms.split(",");
          }
          let companyName = response.data.companyName;
          let registrationNo = response.data.registrationNo;
          let currentYear = response.data.currentYear;
          let splittedCurrentYear = currentYear.split(" ");
          let currentStartingPeriod = splittedCurrentYear[1];
          let currentEndingPeriod = splittedCurrentYear[3];

          let sruTemplateReq = {
            validFromDate: currentStartingPeriod,
            validToDate: currentEndingPeriod,
            statementType: 3,
          };
          sruTemplateReqArray.push(sruTemplateReq);

          this.setState({
            companyName: companyName,
            registrationNo: registrationNo,
          });
          axios
            .post(
              "/SRUHeader/getSRUTemplateLinesByFY",
              sruTemplateReqArray,
              userSessionAuthKeyReturnValue
            )
            .then((Response) => {
              sruTemplateResponse = Response.data;
              this.dataManipulation(sruTemplateResponse, true, false);
            });
          tabTitleArray = sruTemplateResponse;
          this.openAll(sruTemplateResponse);
        });
    }
  }

  dataManipulation(resp, isInitialRender) {
    if (isInitialRender) {
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
      });

      this.setState({
        sruValue: resp,
      });
    }
  }

  AmountOnBlur(e, num) {
    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );

    let possibleAccessablePagesListLSArray =
      possibleAccessablePagesListLSString &&
      JSON.parse(possibleAccessablePagesListLSString);

    possibleAccessablePagesListLSArray.push("INK2S");

    let possibleNavigationPagesListString = JSON.stringify(
      possibleAccessablePagesListLSArray
    );

    localStorage.setItem(
      "possibleAccessablePages",
      possibleNavigationPagesListString
    );

    const { INK2Value } = this.state;
    let custEnteredValue = e.target.value;
    custEnteredValue = custEnteredValue.replaceAll(",", "");
    custEnteredValue = custEnteredValue.replaceAll("kr", "");
    custEnteredValue = Number(custEnteredValue);
    let insertLinesArray = [];
    let insertLinesObj = {
      currentYearValue: custEnteredValue,
      isHeader: num["isHeader"],
      lineNo: num["lineNo"],
      lineType: num["lineType"],
      name: num["name"],
      no: num["no"],
      parentLineNo: num["parentLineNo"],
      reportGUID: currentReportGUIDFromUrl,
      statementType: "INK2R",
    };
    insertLinesArray.push(insertLinesObj);

    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/SRULines/insertAndUpdateLines",
          insertLinesArray,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            this.dataManipulation(INK2Value, false);
          }
        });
    }
  }

  callback = (incomedeclaration2SRU, session, link, status) => {
    const { sessionDetails } = this.props;
    let sessionFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      this.props.history.push(incomedeclaration2SRU + sessionFromLS + link);
    }
    this.setState({
      reRender: true,
    });
  };
  navigateToNextPages(side) {
    const { sessionDetails } = this.props;
    let uuid = sessionDetails.sessionDetails.values.uuid;
    let index = selectedForms.indexOf(currentPageName);

    if (side == "next") {
      // Go To Next Page
      let nextPageName = selectedForms[index + 1];
      if (nextPageName != "" && nextPageName != undefined) {
        // Go To INK2R/INK2S
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/" + nextPageName
        );
      }
      // Go To Payment Page
      if (nextPageName == undefined) {
        this.props.history.push("/income-declaration-2/" + uuid + "/payment");
      }
    } else {
      // Go To Previous Page
      let previousPageName = selectedForms[index - 1];
      if (previousPageName == undefined) {
        // Go To Company Info Page
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/companyInfo"
        );
      } else {
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/" + previousPageName
        );
      }
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  accordionHeader(header) {
    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "41%", marginTop: "7px", marginLeft: "2%" }}
        >
          <label
            className="SRU-INK2SFieldsStyle"
            style={{ marginLeft: "-21px" }}
          >
            {header}
          </label>
        </Col>
      </Row>
    );
  }

  setClickedIndex(idx, parentIdx, number) {
    this.setState({
      clickedIndex: idx,
      clickedParentIndex: parentIdx,
      columnIndex: number,
    });
  }


  render() {
    const {
      sideBarStatus,
      toggleOpen,
      activeIndex,
      sruValue,
      companyName,
      registrationNo,
      clickedIndex,
      clickedParentIndex,
      columnIndex,
    } = this.state;
    let { showWIPStatus } = this.props;
    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }
    return (
      <div>
        {/* <Toast
          ref={(el) => {
            this.toast = el;
          }}
          className="SRU-companyInfoErrorToast"
          style={{ marginTop: "34%" }}
        ></Toast> */}

        <SRUNavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />

        <div
          className={
            pageUnderWIP ? "companyInfoSideBarWithWIP" : "companyInfoSideBar"
          }
        >
          <Sidebar
            children="INK2S"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback.bind(this)}
          />
        </div>
        <div>
          <Row
            className={
              sideBarStatus
                ? "SRU-INK2SFYStyle"
                : "SRU-INK2SFYStyle-sidebar-close"
            }
          >
            <div className="SRU-INK2Sparent">
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <div>
                  <div className="SRU-Page_Title_INK2S">
                    <div className="SRU-Page_Title_T1_INK2S">
                      {this.state.companyName}
                      <div>
                        {sruTemplateReqArray.map((selectedYear, fyIdx) => {
                          return (
                            <div className="SRU-INK2SFYMainDiv">
                              {selectedYear.validFromDate}
                              -{" "} {selectedYear.validToDate}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="SRU-INK2SAmountBoxCol" sruTemplateReqArray>

              </Col>
              <Col>
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  outlined
                  style={{ borderRadius: "70px" }}
                  className="SRU-pdf_view_INK2S"
                  tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
                  tooltipOptions={{ position: "bottom" }}
                // onClick={() => this.navigateToPDF()}
                // onClick={() => previewPdf(currentReportGUIDFromUrl)}
                />
              </Col>

              <div className="SRU-INK2SExapndCollapsToggleLbl">
                {toggleOpen == true ? (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-down"
                    title="Expand"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="SRU-toggle-open-INK2S"
                  />
                ) : (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-up"
                    title="Collapse"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="SRU-toggle-open-INK2S"
                  />
                )}
              </div>
            </div>
          </Row>
        </div>

        <div
          className={
            sideBarStatus ? "SRU-INK2SSideBarOpen" : "SRU-INK2SSideBarClose"
          }
        >
          <div>
            <Row className="SRU-INK2SParentRow">
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{
                  width: "100%",
                }}
              >
                {sruValue != undefined && (
                  <Accordion
                    multiple
                    activeIndex={activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                    ref={this.myRef}
                  >
                    {Object.keys(sruValue).map((heading, parentIdx) => {
                      let responseArray = sruValue[heading];
                      responseArray = [...responseArray].sort(
                        (a, b) => a.lineNo - b.lieNo
                      );
                      let header = responseArray[0].name;

                      let numbers = responseArray;
                      numbers = numbers.filter(
                        (num) => num.lineType != "Header"
                      );
                      let firstHalf = [];
                      let secondHalf = [];

                      for (let i = 0; i < Math.ceil(numbers.length / 2); i++) {
                        firstHalf.push(numbers[i]);
                      }
                      for (
                        let i = Math.ceil(numbers.length / 2);
                        i < numbers.length;
                        i++
                      ) {
                        secondHalf.push(numbers[i]);
                      }

                      return (
                        <AccordionTab
                          header={this.accordionHeader(header)}
                          ref={this.myRef}
                           className="SRU-INK2-accordion-Tab"
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2,1fr)",
                              marginLeft: "5%",
                            }}
                          >
                            <div>
                              {firstHalf.map((num, idx) => {
                                switch (num.valueType) {
                                  case "Positive":
                                    num.valueType = (
                                      <i className="pi pi-plus-circle" />
                                    );
                                    break;
                                  case "Negative":
                                    num.valueType = (
                                      <i className=" pi pi-minus-circle" />
                                    );
                                    break;
                                  case "Equal":
                                    num.valueType = "=";
                                    break;
                                  case "Yes/No":
                                    num.valueType = "Yes/No";
                                    break;
                                  default:
                                    num.valueType = "";
                                }
                                if (num.valueType != "Yes/No") {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="SRU-INK2SMain_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                    parentIdx &&
                                                  columnIndex == 1 && (
                                                    <span>
                                                {num.mapToAccountNos != "" && (
                                                  <div className={`tooltip-container ${clickedIndex === idx ? 'show' : ''}`}>
                                                  <FcInfo style={{marginLeft: "10px"}}/>
                                                      <span className="tooltip-text">
                                                      Mapping Number = {num.mapToAccountNos}
                                                      </span>
                                                </div>
                                                )}
                                                    </span>
                                                    )
                                                    }
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              <td>{num.valueType}</td>
                                              <td></td>
                                              <td>
                                                <InputNumber
                                                  className="SRU-ISAmountBoxDisabled"
                                                  mode="decimal"
                                                  suffix=" kr"
                                                  locale="sv-SE"
                                                  placeholder="kr"
                                                  onBlur={(e) => {
                                                    this.AmountOnBlur(e, num);
                                                  }}
                                                  ref={this.setRef(idx)}
                                                  // onMouseDown={(e) => e.preventDefault()} 
                                                  onClick={() => {this.setClickedIndex(idx,parentIdx,1)}}
                                                
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="SRU-INK2SMain_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                  parentIdx &&
                                                  columnIndex == 1 && (
                                                    <span>
                                                      {num.mapToAccountNos !=
                                                        "" && (
                                                          <FcInfo
                                                            style={{
                                                              marginLeft: "10px",
                                                            }}
                                                            title={
                                                              "Mapping Number = " +
                                                              num.mapToAccountNos
                                                            }
                                                          />
                                                        )}
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              {/* <td>{num.valueType}</td> */}
                                              <td>
                                                Yes
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedYesbtn
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedYesbtn: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>

                                              <td>
                                                No
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedNobtn
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedNobtn: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div>
                              {secondHalf.map((num, idx) => {
                                switch (num.valueType) {
                                  case "Positive":
                                    num.valueType = (
                                      <i className="pi pi-plus-circle" />
                                    );
                                    break;
                                  case "Negative":
                                    num.valueType = (
                                      <i className=" pi pi-minus-circle" />
                                    );
                                    break;
                                  case "Equal":
                                    num.valueType = "=";
                                    break;
                                  case "Yes/No":
                                    num.valueType = "Yes/No";
                                    break;
                                  default:
                                    num.valueType = "";
                                }
                                if (num.valueType != "Yes/No") {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="SRU-INK2SMain_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                    parentIdx &&
                                                  columnIndex == 2 && (
                                                    <span>
                                                {num.mapToAccountNos != "" && (
                                                  <div className={`tooltip-container ${clickedIndex === idx ? 'show' : ''}`}>
                                                  <FcInfo style={{marginLeft: "10px"}}/>
                                                      <span className="tooltip-text">
                                                      Mapping Number = {num.mapToAccountNos}
                                                      </span>
                                                </div>
                                                )}
                                                    </span>
                                                    )
                                                    }
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              <td>{num.valueType}</td>
                                              <td></td>
                                              <td>
                                                <InputNumber
                                                  className="SRU-ISAmountBoxDisabled"
                                                  mode="decimal"
                                                  suffix=" kr"
                                                  locale="sv-SE"
                                                  placeholder="kr"
                                                  // prefix={num.valueType}
                                                  onBlur={(e) => {
                                                    this.AmountOnBlur(e, num);
                                                  }}
                                                  ref={this.setRef(idx)}
                                                  //onMouseDown={(e) => e.preventDefault()} 
                                                  onClick={() => {this.setClickedIndex(idx,parentIdx,2)}}
                                                
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          className="SRU-INK2SMain_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                  parentIdx &&
                                                  columnIndex == 2 && (
                                                    <span>
                                                      {num.mapToAccountNos !=
                                                        "" && (
                                                          <FcInfo
                                                            style={{
                                                              marginLeft: "10px",
                                                            }}
                                                            title={
                                                              "Mapping Number = " +
                                                              num.mapToAccountNos
                                                            }
                                                          />
                                                        )}
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              {/* <td>{num.valueType}</td> */}
                                              <td>
                                                Yes
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedYesbtn2
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedYesbtn2: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>

                                              <td>
                                                No
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedNobtn2
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedNobtn2: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </AccordionTab>
                      );
                    })}
                  </Accordion>
                )}
              </Col>
            </Row>
          </div>

          <center>
            <Button
              label="Previous"
              aria-label="year-btn-save"
              onClick={() => this.navigateToNextPages("previous")}
              style={{
                marginTop: "2%",
                marginRight: "1%",
              }}
              className="ISSaveBtn"
            />
            <Button
              label="Next"
              aria-label="year-btn-save"
              onClick={() => this.navigateToNextPages("next")}
              style={{
                marginTop: "2%",
              }}
              className="ISSaveBtn"
            />
          </center>
          <br></br>
          <br></br>
        </div>

        <ScrolltoTop />
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(INK2S);
