import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Sidebar from "../Sidebar/Sidebar";
import { connect } from "react-redux";
import moment from "moment";
import "./BalanceSheet.css";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import { Toast } from "primereact/toast";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

// import Header from "../Menu/Header";
// import Switch from "react-switch";
// import Footerpage from "../Footerpage/Footerpage";

let userSelectedLanguage;
let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;

let balanceSheetTemplateReqArray = [],
  balanceSheetTemplateResponse = {},
  pdfLink = "",
  gLatestCustomLineNo,
  linkSharedUser,
  lineSumArray = [],
  tabTitleArray = [],
  sideBarGreenTickPages = [],
  currentReportGUIDFromUrl = "";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    activeSideBar: state.activeSideBar.activeSideBar,
    incomeStatement: state.incomeStatement.incomeStatement,
    balanceSheet: state.balanceSheet.balanceSheet,
    readOnly: state.readOnly.readOnly,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};

let steps = [

  {
    content: 'You can view the PDF file up to the completed process.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.pdf_view_balancesheet',
    title: 'PDF File',
    disableBeacon: true,
  },
  {
    content: 'You can minimize or maximize the tab by clicking this button.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.toggle-open',
    title: 'Accordion Tab',
    disableBeacon: true,
  },
  {
    content: 'Lines can be added as needed for the user to fill in their own information.',
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '#ISAddNewLineIcon',
    title: 'Add New Line',
    disableBeacon: true,
  }
];

class BalanceSheetNew extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      activeIndex: [0],
      checked: true,
      sideBarStatus: true,
      latestCustomLineNo: 0,
      reportGUID: "",
      finalSumLbl: "Årets resultat",
      toggleOpen: false,
      headerCount: 0,
      isLoading: true,
      balanceSheetValue: "",
      showDialog: false,
      companyInformation: "",
      companyName: "",
      currentFinancialYear: "",
      run: false,
      headerDetails: "",
    };
  }

  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let headerIdxList = [];
      Object.keys(tabTitleArray).map((heading, idx) => {
        headerIdxList.push(idx);
      });
      this.setState({ activeIndex: headerIdxList });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }

  openAll(resp) {
    let headerIdxList = [];
    Object.keys(resp).map((heading, idx) => {
      headerIdxList.push(idx);
    });
    let open = headerIdxList;
    this.setState({ activeIndex: open });
  }

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });


    checkForPreviousYear() {
      const { balanceSheetValue,headerDetails } = this.state;
      if (
        headerDetails.previousYear1 == null ||
        headerDetails.previousYear1 == ""
      ) {
        //Get the state and filter the financial year value 0 and store it in a variable and call the update json api and update it and rerender
          let filteredBSValue = []
          
          {Object.keys(balanceSheetValue).map(
            (heading, parentIdx) => {
              let responseArray = balanceSheetValue[heading];
              responseArray = [...responseArray].sort((a, b) => a.lineNo - b.lineNo);
              responseArray = responseArray.filter((val) => val.financialYearValue == 0)
              filteredBSValue.push(responseArray)
          })
          this.setState({balanceSheetValue:filteredBSValue})
  
          if (userSessionAuthKeyReturnValue) {
        let currentPageName = localStorage.getItem("currentPageName");
        let updateJsonTableObj = {
          pageName: currentPageName,
          guid: currentReportGUIDFromUrl,
          json: JSON.stringify(filteredBSValue),
        };
        axios
          .post(
            "/annualReportLine/updateLinesJsonTable",
            updateJsonTableObj,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {});
        }
      }
    } else {
    }
    }


  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let { companyInformation, balanceSheet, sessionDetails, sideBarReducer } =
      this.props;

    localStorage.setItem("currentPageName", "Balance Sheet");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    pdfLink = "#annualreport/" + currentReportGUIDFromUrl + "/balancePdf";

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseDate = response.data;
          this.setState({ headerDetails: responseDate });
          // let localData = localStorage.getItem("rebelSkoolUser");
          // let localObject = "";
          // if (localData != "undefined") {
          //   localObject = JSON.parse(localData);

          if (responseDate != "") {
            let reportAccessReq = {
              reportId: responseDate.reportID,
            };
            axios
              .post(
                "/annualReport/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    linkSharedUser = 2; //Write
                    break;
                  case 3:
                    linkSharedUser = 3; //Read Only
                    break;
                  case 4:
                    linkSharedUser = 4; //No Access
                    break;
                }
              })
              .catch((error) => {
                let noAccessURL =
                  "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                this.props.history.push(noAccessURL);
              });
          }
          // }

          if (responseDate.companyName != "") {
            this.setState({
              companyName: responseDate.companyName,
            });
          }

          if (responseDate.currentYear != "")
            this.setState({
              currentFinancialYear: responseDate.currentYear,
            });
        });

      let getJsonLines = {
        guid: currentReportGUIDFromUrl,
        pageName: "Balance Sheet",
      };

      let currentReportCompanyName = localStorage.getItem(
        "navigatedCompanyName"
      );
      if (currentReportCompanyName != "") {
        this.setState({
          companyInformation: currentReportCompanyName,
        });
      }

      axios
        .post(
          "/annualReportLine/getLinesJson",
          getJsonLines,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseData = response.data.balanceSheet;

          if (responseData == undefined) {
            responseData = "";
          }

          if (responseData != "") {
            let jsonConvertedResponseData = JSON.parse(responseData);
            let financialYearFromJsonResponse = [];
            Object.keys(jsonConvertedResponseData).map((i, idx) => {
              if (i == "financialYear") {
                financialYearFromJsonResponse = [
                  ...jsonConvertedResponseData[i],
                ];
              }
            });

            balanceSheetTemplateReqArray = financialYearFromJsonResponse;

            delete jsonConvertedResponseData.financialYear;

            tabTitleArray = jsonConvertedResponseData;
            this.openAll(jsonConvertedResponseData);

            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });
              });

            this.setState({
              balanceSheetValue: jsonConvertedResponseData,
              isLoading: false,
            });
            this.checkForPreviousYear();
          } else {
            balanceSheetTemplateReqArray = [];

            if (companyInformation != undefined) {
              if (userSessionAuthKeyReturnValue) {
                companyInformation != undefined &&
                  companyInformation.financialYear.length &&
                  companyInformation.financialYear.map((financialYear, idx) => {
                    let balanceSheetTemplateReq = {
                      validFromDate: moment(financialYear.from).format(
                        "YYYY-MM-DD"
                      ),
                      validToDate: moment(financialYear.to).format(
                        "YYYY-MM-DD"
                      ),
                      statementType: 2,
                    };
                    balanceSheetTemplateReqArray.push(balanceSheetTemplateReq);
                  });

                axios
                  .post(
                    "/annualReport/getIncomeBalanceTemplateYearWise",
                    balanceSheetTemplateReqArray,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {
                    balanceSheetTemplateResponse = response.data;
                    this.dataManipulation(
                      balanceSheetTemplateResponse,
                      true,
                      false
                    );
                    tabTitleArray = balanceSheetTemplateResponse;
                    this.openAll(balanceSheetTemplateResponse);
                  });
              }
            } else {
              let getJsonLines = {
                guid: currentReportGUIDFromUrl,
                pageName: "Company Information",
              };

              axios
                .post(
                  "/annualReportLine/getLinesJson",
                  getJsonLines,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  let responseData = response.data.companyInformation;

                  if (responseData == undefined) {
                    responseData = "";
                  }

                  if (responseData != "" && userSessionAuthKeyReturnValue) {
                    let jsonConvertedResponseData = JSON.parse(responseData);
                    companyInformation = jsonConvertedResponseData.values;

                    jsonConvertedResponseData.values.financialYear.map(
                      (financialYear, idx) => {
                        let balanceSheetTemplateReq = {
                          validFromDate: moment(financialYear.from).format(
                            "YYYY-MM-DD"
                          ),
                          validToDate: moment(financialYear.to).format(
                            "YYYY-MM-DD"
                          ),
                          statementType: 2,
                        };
                        balanceSheetTemplateReqArray.push(
                          balanceSheetTemplateReq
                        );
                      }
                    );

                    axios
                      .post(
                        "/annualReport/getIncomeBalanceTemplateYearWise",
                        balanceSheetTemplateReqArray,
                        userSessionAuthKeyReturnValue
                      )
                      .then((response) => {
                        balanceSheetTemplateResponse = response.data;
                        this.dataManipulation(
                          balanceSheetTemplateResponse,
                          true,
                          false
                        );
                        tabTitleArray = balanceSheetTemplateResponse;
                        this.openAll(balanceSheetTemplateResponse);
                      });
                  }
                });
            }
          }
        });
    }
  }

  customOnChange(value, template, heading) {
    const { balanceSheetValue } = this.state;

    Object.keys(balanceSheetValue).map((i, idx) => {
      let filteredObjectsToRemove = balanceSheetValue[i].filter(
        (item) => item.lineNo == template.lineNo
      );

      filteredObjectsToRemove.map((j, jdx) => {
        j.name = value;
      });
    });

    this.setState({
      reRender: true,
    });
  }

  onHide() {
    this.setState({
      showDialog: false,
    });
  }

  forcePageNavigation(navigateToPageName) {
    let { sideBarReducer, sessionDetails } = this.props;

    const annualReport = "/annualreport/";
    let sideBarReducerValues = [];
    sideBarReducerValues = sideBarReducer.values;
    sideBarReducerValues.push("Balance Sheet");
    if (sideBarGreenTickPages.length == 0)
      sideBarReducer.values = sideBarReducerValues;

    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );

    let possibleAccessablePagesListLSArray = JSON.parse(
      possibleAccessablePagesListLSString
    );

    if (!possibleAccessablePagesListLSArray.includes("Notes")) {
      possibleAccessablePagesListLSArray.push(
        "Company Information",
        "Income Statement",
        "Balance Sheet",
        "Notes"
      );

      let updatePossibleNavigationPagesListString = JSON.stringify(
        possibleAccessablePagesListLSArray
      );

      localStorage.setItem(
        "possibleAccessablePages",
        updatePossibleNavigationPagesListString
      );
    }

    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/" + navigateToPageName
    );
  }
  renderFooter() {
    return (
      <div>
        <Button
          label={TranslationFile.yesBtn[userSelectedLanguage]}
          icon="pi pi-check"
          onClick={() => this.forcePageNavigation("notes")}
          className="p-button-text"
        />

        <Button
          label={TranslationFile.noBtn[userSelectedLanguage]}
          icon="pi pi-times"
          onClick={() => this.onHide()}
          // className="p-button-text"
          autoFocus
        />
      </div>
    );
  }

  pageNavigation(navigateToPageName) {
    const { balanceSheetValue, showDialog } = this.state;
    let { sideBarReducer, sessionDetails } = this.props;

    if (navigateToPageName == "notes") {
      let finalLineSumArrays = [];
      Object.keys(balanceSheetValue).map((heading, idx) => {
        let linesArray = [...balanceSheetValue[heading]].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        const finalLineSum = linesArray.filter(
          (obj) => obj.isLineSum && obj.lineLevel == 1
        );

        finalLineSumArrays.push(finalLineSum);
      });

      let fyWiseLineSumObj = {};

      finalLineSumArrays.map((arrays, idx) => {
        let fyWiseLineSumArray = [];
        arrays = arrays.filter((obj) => obj.financialYearValue == 0);
        arrays.map((arrayValue, arrayValudIdx) => {
          if (fyWiseLineSumObj[arrayValue.year] == undefined) {
            fyWiseLineSumArray = [];
            fyWiseLineSumArray.push(arrayValue.lineAmount);
            fyWiseLineSumObj[arrayValue.year] = fyWiseLineSumArray;
          } else {
            let fyWiseLineSumExistingArray = fyWiseLineSumObj[arrayValue.year];
            fyWiseLineSumExistingArray.push(arrayValue.lineAmount);
            fyWiseLineSumObj[arrayValue.year] = fyWiseLineSumExistingArray;
          }
        });
      });

      let isLineSumMatch = false,
        currentFyLineSumArray = [];
      Object.keys(fyWiseLineSumObj).map((i, idx) => {
        lineSumArray = [...fyWiseLineSumObj[i]];
        if (Number(lineSumArray[1].toFixed(0)) < 0) {
          let skulderAbs = Math.abs(lineSumArray[1]);
          if (lineSumArray[0].toFixed(0) == skulderAbs.toFixed(0)) {
            isLineSumMatch = true;
          }
        }
      });

      if (!isLineSumMatch) {
        this.setState({
          showDialog: true,
        });
      } else {
        this.setState({
          showDialog: false,
        });

        this.forcePageNavigation(navigateToPageName);
      }
    } else {
      this.forcePageNavigation(navigateToPageName);
    }
  }

  dataManipulation(resp, isInitialRender, isOnChangeCall) {
    let { companyInformation, sessionDetails, login, balanceSheet } =
      this.props;
    let fieldExistsInMultiYear = [],
      lineNoArray = [],
      lineDetailsArray = [];

    if (userSessionAuthKeyReturnValue) {
      delete resp.financialYear;
      if (balanceSheet.values != undefined && !isOnChangeCall) {
        resp = balanceSheet.values;
      } else {
        if (isInitialRender) {
          // calculating lineAmount
          Object.keys(resp).map((i, idx) => {
            let sortedRespArray = [...resp[i]].sort(
              (a, b) => a.lineNo - b.lineNo
            );
            sortedRespArray.map((sortedResp, sortedRespIdx) => {
              let range = this.convert(sortedResp.range, "-");
              if (range[0] == 0) {
                range = this.convert(sortedResp.lineSumRange, "-");
              }
            });
          });
        }

        // calculating yearwise lineAmount
        Object.keys(resp).map((i, idx) => {
          let sortedRespArray = [...resp[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          sortedRespArray = sortedRespArray.filter(
            (obj) => obj.lineSumRange != ""
          );
          sortedRespArray.length &&
            sortedRespArray.map((sortedResp, sortedRespIdx) => {
              let range = this.convert(sortedResp.lineSumRange, "-");
              let fyYearValue = sortedResp.year;
              let lineSumValue = 0;

              Object.keys(resp).map((innerRespObj, innerRespIdx) => {
                let innerSortedRespArray = [...resp[innerRespObj]].sort(
                  (a, b) => a.lineNo - b.lineNo
                );

                innerSortedRespArray = innerSortedRespArray.filter(
                  (obj) =>
                    obj.year == fyYearValue &&
                    obj.lineType == "Line" &&
                    obj.lineAmount != undefined
                );

                innerSortedRespArray.map(
                  (innerSortedRespValue, innerSortedRespIdx) => {
                    if (range.includes(innerSortedRespValue.lineNo)) {
                      lineSumValue += innerSortedRespValue.lineAmount;
                    }
                  }
                );
              });
              sortedResp["lineAmount"] = lineSumValue;
            });
        });
      }

      //setting currentYearValue and previousYear value
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
        sortedRespArray.map((sortedResp, sortedRespIdx) => {
          if (sortedResp.financialYearValue == 0) {
            sortedResp["currentYearValue"] = sortedResp.lineAmount;
          } else {
            let yearValue = sortedResp.financialYearValue * -1;
            sortedResp["previousYear" + yearValue + "Value"] =
              sortedResp.lineAmount;
          }
          sortedResp["reportGUID"] = currentReportGUIDFromUrl;
          sortedResp["createdByUserID"] = login == undefined ? 0 : login.userID;
        });
      });

      //merging muti year object
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
        sortedRespArray.map((sortedResp, sortedRespIdx) => {
          const filtered = sortedRespArray.filter(
            (obj) => obj.lineNo == sortedResp.lineNo
          );
          fieldExistsInMultiYear.push(Object.assign({}, ...filtered));
        });
      });

      fieldExistsInMultiYear.length &&
        fieldExistsInMultiYear.map((i, idx) => {
          if (!lineNoArray.includes(i.lineNo)) {
            lineNoArray.push(i.lineNo);
            lineDetailsArray.push(i);
          }
        });

      if (lineDetailsArray.length > 0) {
        axios
          .post(
            "/annualReportLine/insertLines",
            lineDetailsArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => { });
      }

      this.setState({
        balanceSheetValue: resp,
        isLoading: false,
      });

      balanceSheet.values = resp;

      let responseJson = resp;
      responseJson["financialYear"] = balanceSheetTemplateReqArray;
      // if (userSessionAuthKeyReturnValue) {
      let currentPageName = localStorage.getItem("currentPageName");
      let updateJsonTableObj = {
        pageName: "Balance Sheet",
        guid: currentReportGUIDFromUrl,
        json: JSON.stringify(responseJson),
      };
      axios
        .post(
          "/annualReportLine/updateLinesJsonTable",
          updateJsonTableObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => { });
      // }
    }
  }

  accordionHeader(header) {
    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "41%", marginTop: "7px" }}
        >
          <label className="BSFieldsStyle">{header}</label>
        </Col>
      </Row>
    );
  }

  AmountOnBlur(e, mutilYear, heading) {
    const { balanceSheetValue } = this.state;
    let custEnteredValue = e.target.value;
    custEnteredValue = custEnteredValue.replaceAll(",", "");

    balanceSheetValue[heading].map((i, idx) => {
      if (i.lineNo == mutilYear.lineNo && i.year == mutilYear.year) {
        let convertedToNumb = Number(custEnteredValue.replaceAll(/\s/g, ""));
        let spacesRemoved = custEnteredValue.replaceAll(/\s/g, "");
        const firstChar = spacesRemoved.charAt(0);
        let minusArray = ["-", "−", "‑", "−"];
        if (minusArray.includes(firstChar)) {
          const newStr = spacesRemoved.slice(1);
          return (i["lineAmount"] = -1 * newStr);
        } else return (i["lineAmount"] = Number(spacesRemoved));
      }
    });
    this.setState({
      balanceSheetValue: "",
    });

    this.dataManipulation(balanceSheetValue, false, true);
  }

  AmountOnChange(e, mutilYear, heading) {
    const { balanceSheetValue } = this.state;
    if (e.value != null) {
      balanceSheetValue[heading].map((i, idx) => {
        if (i.lineNo == mutilYear.lineNo && i.year == mutilYear.year) {
          return (i["lineAmount"] = e.value);
        }
      });
      this.setState({
        balanceSheetValue: "",
      });

      this.dataManipulation(balanceSheetValue, false, true);
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  onClickAddNewRowIS(name, BSTemplateFieldOutsideMap) {
    const { balanceSheetValue } = this.state;

    let tempBalanceSheetValue = balanceSheetValue;
    if (userSessionAuthKeyReturnValue) {
      Object.keys(tempBalanceSheetValue).map((heading, idx) => {
        let sortedRespArray = [...tempBalanceSheetValue[heading]].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        const emptyCustRowArray = sortedRespArray.filter(
          (obj) => obj.name == ""
        );

        if (emptyCustRowArray.length) {
          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              emptyCustRowArray[0],
              userSessionAuthKeyReturnValue
            )
            .then((response) => { });
        }

        const emptyCustRowRemovedArray = sortedRespArray.filter(
          (obj) => obj.name != ""
        );
        tempBalanceSheetValue[heading] = emptyCustRowRemovedArray;
      });

      let isCustLineInsertNeeded = false;
      let newRowObj = {};
      Object.keys(tempBalanceSheetValue).map((heading, idx) => {
        if (heading == name) {
          let linesList = [];
          linesList = tempBalanceSheetValue[heading].filter(
            (obj) => !obj.isLineSum
          );

          let emptyNameRowList = [];
          emptyNameRowList = linesList.filter((obj) => obj.name == "");

          if (emptyNameRowList.length == 0) {
            linesList = tempBalanceSheetValue[heading].filter(
              (obj) => !obj.isLineSum && !obj.isFinalSum
            );

            let lineNo = BSTemplateFieldOutsideMap.lineNo + 1;

            this.setState({
              latestCustomLineNo: lineNo,
            });

            gLatestCustomLineNo = BSTemplateFieldOutsideMap.lineNo + 1;

            balanceSheetTemplateReqArray.map((selectedYear, fyIdx) => {
              newRowObj = {
                name: "",
                range: "",
                year: selectedYear.validFromDate,
                statementType: "Balance Sheet",
                isLineSum: false,
                isHeader: false,
                lineSumRange: "",
                isFinalSum: false,
                no: BSTemplateFieldOutsideMap.no,
                lineNo: lineNo,
                lineType: "Line",
                lineLevel: BSTemplateFieldOutsideMap.lineLevel,
                financialYearValue: fyIdx == 0 ? 0 : fyIdx * -1,
                reportGUID: BSTemplateFieldOutsideMap.reportGUID,
                createdByUserID: BSTemplateFieldOutsideMap.createdByUserID,
                parentLineNo: BSTemplateFieldOutsideMap.parentLineNo,
                isCustomRow: true,
              };

              this.setState({
                reportGUID: currentReportGUIDFromUrl,
              });

              linesList.push(newRowObj);

              let responseArray = tempBalanceSheetValue[heading];
              responseArray.push(newRowObj);

              responseArray = [...responseArray].sort(
                (a, b) => a.lineNo - b.lineNo
              );

              tempBalanceSheetValue[heading] = responseArray;
              this.setState({
                balanceSheetValue: tempBalanceSheetValue,
              });
            });
            isCustLineInsertNeeded = true;
          }
        }
      });

      this.setState({
        balanceSheetValue: tempBalanceSheetValue,
      });

      if (isCustLineInsertNeeded) {
        axios
          .post(
            "/annualReportLine/insertCustomLine",
            newRowObj,
            userSessionAuthKeyReturnValue
          )
          .then((response) => { });
      }

      this.setState({
        reRender: true,
      });
    }
  }

  onClickAddNewRow(name, BSTemplateFieldOutsideMap) {
    const { balanceSheetValue } = this.state;

    if (userSessionAuthKeyReturnValue) {
      Object.keys(balanceSheetValue).map((heading, idx) => {
        let sortedRespArray = [...balanceSheetValue[heading]].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        const emptyCustRowArray = sortedRespArray.filter(
          (obj) => obj.name == ""
        );

        if (emptyCustRowArray.length) {
          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              emptyCustRowArray[0],
              userSessionAuthKeyReturnValue
            )
            .then((response) => { });
        }

        const emptyCustRowRemovedArray = sortedRespArray.filter(
          (obj) => obj.name != ""
        );
        balanceSheetValue[heading] = emptyCustRowRemovedArray;
      });

      let isCustLineInsertNeeded = false;
      let newRowObj = {};
      Object.keys(balanceSheetValue).map((heading, idx) => {
        if (heading == name) {
          let linesList = [];
          linesList = balanceSheetValue[heading].filter(
            (obj) => !obj.isLineSum
          );

          let emptyNameRowList = [];
          emptyNameRowList = linesList.filter((obj) => obj.name == "");

          if (emptyNameRowList.length == 0) {
            linesList = balanceSheetValue[heading].filter(
              (obj) => !obj.isLineSum && !obj.isFinalSum
            );

            let lineNo = BSTemplateFieldOutsideMap.lineNo + 1;
            let calcNextLineNo = false;

            for (
              let i = BSTemplateFieldOutsideMap.lineNo;
              i <= BSTemplateFieldOutsideMap.lineNo + 1000;
              i++
            ) {
              let lineNoObj = [];
              lineNoObj = [...balanceSheetValue[heading]].filter(
                (obj) => obj.lineNo == i
              );
              if (lineNoObj.length == 0 && !calcNextLineNo) {
                lineNo = i;
                calcNextLineNo = true;
              }
            }

            this.setState({
              latestCustomLineNo: lineNo,
            });

            balanceSheetTemplateReqArray.map((selectedYear, fyIdx) => {
              newRowObj = {
                name: "",
                range: "",
                year: selectedYear.validFromDate,
                statementType: "Balance Sheet",
                isLineSum: false,
                isHeader: false,
                lineSumRange: "",
                isFinalSum: false,
                no: BSTemplateFieldOutsideMap.no,
                lineNo: lineNo,
                lineType: "Line",
                lineLevel: BSTemplateFieldOutsideMap.lineLevel,
                financialYearValue: fyIdx == 0 ? 0 : fyIdx * -1,
                reportGUID: BSTemplateFieldOutsideMap.reportGUID,
                createdByUserID: BSTemplateFieldOutsideMap.createdByUserID,
                parentLineNo: BSTemplateFieldOutsideMap.parentLineNo,
                isCustomRow: true,
              };

              this.setState({
                reportGUID: currentReportGUIDFromUrl,
              });

              linesList.push(newRowObj);

              let responseArray = balanceSheetValue[heading];
              responseArray.push(newRowObj);

              responseArray = [...responseArray].sort(
                (a, b) => a.lineNo - b.lineNo
              );

              balanceSheetValue[heading] = responseArray;
            });
            isCustLineInsertNeeded = true;
          }
        }
      });

      if (isCustLineInsertNeeded) {
        axios
          .post(
            "/annualReportLine/insertCustomLine",
            newRowObj,
            userSessionAuthKeyReturnValue
          )
          .then((response) => { });
      }

      this.setState({
        reRender: true,
      });
    }
  }

  validateForm(e, parentHeading, idx, BSTemplateFieldOutsideMap) {
    const { latestCustomLineNo, reportGUID, balanceSheetValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let responseArray = balanceSheetValue[parentHeading];
      responseArray.map((respValue, respIdx) => {
        if (respValue.name == "") {
          respValue.name = e.target.value;
        }
      });

      let sessionDetailsLS = localStorage.getItem("sessionDetails");
      let sessionDetailsParse = JSON.parse(sessionDetailsLS);

      let newRowObj = {
        name: e.target.value,
        reportGUID: currentReportGUIDFromUrl,
        lineNo:
          latestCustomLineNo != 0
            ? latestCustomLineNo
            : BSTemplateFieldOutsideMap.lineNo,
        statementType: "Balance Sheet",
      };

      axios
        .post(
          "/annualReportLine/updateCustomLineName",
          newRowObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = balanceSheetValue;
            responseJson["financialYear"] = balanceSheetTemplateReqArray;

            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");
            let updateJsonTableObj = {
              pageName: "Balance Sheet",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };
            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => { });
            this.setState({ reRender: true });
            // }
          }
        });

      this.setState({ reRender: true });
    }
  }

  deleteCustomRow(customRowToDelete, heading) {
    const { balanceSheetValue } = this.state;

    if (userSessionAuthKeyReturnValue) {
      let objectToDelete = Object.assign({}, ...customRowToDelete);

      Object.keys(balanceSheetValue).map((i, idx) => {
        if (i == heading) {
          let filteredObjectsToRemove = balanceSheetValue[i].filter(
            (item) => item.lineNo == objectToDelete.lineNo
          );

          filteredObjectsToRemove.length &&
            filteredObjectsToRemove.map((ObjToRemove, ObjToRemoveIdx) => {
              balanceSheetValue[i].splice(
                balanceSheetValue[i].indexOf(ObjToRemove),
                1
              );
            });

          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              objectToDelete,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                this.dataManipulation(balanceSheetValue, false, true);
                this.setState({
                  reRender: true,
                });
              }
            });
        }
      });
    }
  }

  languageValue = (e) => { };

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    if (status) {
      this.props.history.push(annualReport + session + link);
    }
    this.setState({
      reRender: true,
    });
  };

  navigateToPDF() {
    window.open(`#/annualreport/${currentReportGUIDFromUrl}/pdf`, "_blank");
  }

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };


  tourStatus = (e) => {
    this.setState({ run: e })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ run: userTourGuide });
    }, 500);
  }



  render() {
    const { readOnly, showWIPStatus } = this.props;
    const {
      checked,
      activeIndex,
      balanceSheetValue,
      sideBarStatus,
      finalSumLbl,
      toggleOpen,
      isLoading,
      companyName,
      currentFinancialYear,
    } = this.state;
    let BSFieldsArrayWithoutDuplicate = [];
    let isFieldExistsAlready = false;

    delete balanceSheetValue.financialYear;

    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }

    return (
      <div className="balance-sheet-whole-div">

        <Joyride
          continuous
          run={this.state.run}
          disableBeacon={true}
          showProgress
          showSkipButton
          steps={steps}
          scrollToFirstStep
          scrollToSteps
          scrollOffset={100}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />


        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "34%" }}
        ></Toast>
        <NavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />
        <div
          className={
            pageUnderWIP
              ? "companyInfoSideBarWithWIP"
              : "incomeStatementSideBar"
          }
        >
          <Sidebar
            children="Balance Sheet"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback}
            tourStatus={this.tourStatus.bind(this)}
          />
        </div>

        <div>
          <Row
            // className="BSFYStyle-BS"
            className={sideBarStatus ? "BSFYStyle" : "BSFYStyle-sidebar-close"}
          >
            <div className="BSparent">
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <div>
                  <div className="Page_Title_balanceSheet">
                    <div className="Page_Title_T1_balanceSheet">
                      {companyName}
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                className="BSAmountBoxCol"
                style={{ marginLeft: "-41px" }}
              >
                {balanceSheetTemplateReqArray.map((selectedYear, fyIdx) => {
                  return (
                    <div className="BSFYMainDiv">
                      <label className="BSFromFY">
                        {selectedYear.validFromDate}
                      </label>
                      <label className="BSToFY">
                        - {selectedYear.validToDate}
                      </label>
                    </div>
                  );
                })}
              </Col>
              <Col>
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  outlined
                  style={{ borderRadius: "70px" }}
                  className="pdf_view_balancesheet"
                  tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
                  tooltipOptions={{ position: "bottom" }}
                  // onClick={() => this.navigateToPDF()}
                  onClick={() => previewPdf(currentReportGUIDFromUrl)}
                />
              </Col>
              <div className="BSExapndCollapsToggleLbl">
                {toggleOpen == true ? (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-down"
                    title="Expand"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="toggle-open"
                  />
                ) : (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-up"
                    title="Collapse"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="toggle-open"
                  />
                )}
              </div>
            </div>
          </Row>
        </div>

        <div className={sideBarStatus ? "BSSideBarOpen" : "BSSideBarClose"}>
          {isLoading ? (
            <div className="BSLoading">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            </div>
          ) : (
            <Row style={{ width: "100%" }}>
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{ width: "100%", marginLeft: "12px" }}
                className="BS_Main_Div"
              >
                {balanceSheetValue != undefined && (
                  <Accordion
                    multiple
                    activeIndex={activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                    ref={this.myRef}
                  >
                    {Object.keys(balanceSheetValue).map(
                      (heading, parentIdx) => {
                        BSFieldsArrayWithoutDuplicate = [];
                        let lineSumCount = 0;
                        let responseArray = balanceSheetValue[heading];
                        responseArray = [...responseArray].sort(
                          (a, b) => a.lineNo - b.lineNo
                        );
                        let header = responseArray[0].name;
                        let addNewRowBtn = false;
                        let addNewRowBtnIdx = 0;
                        let isPreviousLineSum = false;

                        return (
                          <AccordionTab
                            header={this.accordionHeader(header)}
                            ref={this.myRef}
                            className="IS-Accordion-Tab"
                          >
                            {responseArray.map(
                              (BSTemplateFieldOutsideMap, idx) => {
                                if (
                                  idx != 0 &&
                                  header != BSTemplateFieldOutsideMap.name
                                ) {
                                  if (
                                    BSFieldsArrayWithoutDuplicate.includes(
                                      BSTemplateFieldOutsideMap.name +
                                      BSTemplateFieldOutsideMap.lineNo
                                    )
                                  ) {
                                    isFieldExistsAlready = true;
                                  } else {
                                    isFieldExistsAlready = false;
                                    BSFieldsArrayWithoutDuplicate.push(
                                      BSTemplateFieldOutsideMap.name +
                                      BSTemplateFieldOutsideMap.lineNo
                                    );
                                  }

                                  let fieldExistsInMultiYear = [];

                                  responseArray.map(
                                    (
                                      BSTemplateFieldInsideMap,
                                      BSTemplateFieldInsideMapIdx
                                    ) => {
                                      if (
                                        BSTemplateFieldInsideMap.name ==
                                        BSTemplateFieldOutsideMap.name &&
                                        BSTemplateFieldInsideMap.lineNo ==
                                        BSTemplateFieldOutsideMap.lineNo &&
                                        !isFieldExistsAlready
                                      ) {
                                        fieldExistsInMultiYear.push(
                                          BSTemplateFieldInsideMap
                                        );
                                      }
                                    }
                                  );

                                  let prevousObject = responseArray[idx - 1];

                                  if (prevousObject.isLineSum) {
                                    isPreviousLineSum = true;
                                  } else {
                                    isPreviousLineSum = false;
                                  }

                                  if (
                                    BSTemplateFieldOutsideMap.isLineSum &&
                                    !isFieldExistsAlready &&
                                    !isPreviousLineSum
                                  ) {
                                    addNewRowBtnIdx = 0;
                                  } else {
                                    addNewRowBtnIdx = addNewRowBtnIdx + 1;
                                  }

                                  return (
                                    <div
                                      style={{
                                        background:
                                          BSTemplateFieldOutsideMap.lineType ==
                                          "Header" &&
                                          BSTemplateFieldOutsideMap.lineLevel <=
                                          2 &&
                                          "#DBE2EF",
                                      }}
                                    >
                                      <Row className="BSFields">
                                        {!isFieldExistsAlready && (
                                          <Col
                                            xs={5}
                                            sm={5}
                                            md={5}
                                            lg={5}
                                            xl={5}
                                          >
                                            {/* {!isFieldExistsAlready && ( */}
                                            <div className="BSLblRowMainsideDiv">
                                              {BSTemplateFieldOutsideMap.isCustomRow ? (
                                                <div>
                                                  <InputTextarea
                                                    autoFocus
                                                    className="BScustomFieldText"
                                                    value={
                                                      BSTemplateFieldOutsideMap.name
                                                    }
                                                    onBlur={(e) =>
                                                      this.validateForm(
                                                        e,
                                                        heading,
                                                        idx,
                                                        BSTemplateFieldOutsideMap
                                                      )
                                                    }
                                                    onChange={(e) => {
                                                      this.customOnChange(
                                                        e.target.value,
                                                        BSTemplateFieldOutsideMap,
                                                        heading
                                                      );
                                                    }}
                                                    style={{
                                                      marginLeft:
                                                        BSTemplateFieldOutsideMap.lineLevel +
                                                        BSTemplateFieldOutsideMap.lineLevel +
                                                        3 +
                                                        "em",
                                                    }}
                                                    placeholder="Enter your description"
                                                  />
                                                </div>
                                              ) : BSTemplateFieldOutsideMap.isLineSum ? (
                                                <div
                                                  className="BSTotalFieldsStyle"
                                                  id={
                                                    !addNewRowBtn &&
                                                    "BSLblRowInsideDivId"
                                                  }
                                                  style={{
                                                    marginLeft:
                                                      prevousObject.lineLevel +
                                                      prevousObject.lineLevel +
                                                      "em",
                                                  }}
                                                >
                                                  <label className="BSTotalFieldsStyle">
                                                    {
                                                      BSTemplateFieldOutsideMap.name
                                                    }
                                                  </label>
                                                </div>
                                              ) : BSTemplateFieldOutsideMap.lineType ==
                                                "Header" &&
                                                BSTemplateFieldOutsideMap.lineLevel >
                                                1 ? (
                                                <label
                                                  className="BSSubHeadingStyle"
                                                  style={{
                                                    marginLeft:
                                                      BSTemplateFieldOutsideMap.lineLevel +
                                                      BSTemplateFieldOutsideMap.lineLevel +
                                                      "em",
                                                  }}
                                                >
                                                  {
                                                    BSTemplateFieldOutsideMap.name
                                                  }
                                                </label>
                                              ) : (
                                                !BSTemplateFieldOutsideMap.isFinalSum && (
                                                  <label
                                                    className="BSFieldsStyle"
                                                    style={{
                                                      marginLeft:
                                                        BSTemplateFieldOutsideMap.lineLevel +
                                                        BSTemplateFieldOutsideMap.lineLevel +
                                                        "em",
                                                    }}
                                                  >
                                                    {
                                                      BSTemplateFieldOutsideMap.name
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </Col>
                                        )}

                                        {!isFieldExistsAlready && (
                                          <Col
                                            xs={7}
                                            sm={7}
                                            md={7}
                                            lg={7}
                                            xl={7}
                                            className="BSAmountBoxCol"
                                          >
                                            {fieldExistsInMultiYear.map(
                                              (mutilYear, multiYearIdx) => {
                                                if (!mutilYear.isFinalSum)
                                                  return (
                                                    <div className="BSAmountBoxRowDiv">
                                                      {mutilYear.isLineSum ? (
                                                        <InputNumber
                                                          inputId="integeronly"
                                                          className="BSAmountBoxDisabled"
                                                          disabled={true}
                                                          value={Math.round(
                                                            mutilYear.lineAmount
                                                          )}
                                                          id={
                                                            addNewRowBtnIdx ==
                                                            0 &&
                                                            "balanceSheetIsFirstLineSum"
                                                          }
                                                          useGrouping={true}
                                                          locale="sv-SE"
                                                        />
                                                      ) : mutilYear.lineAmount !=
                                                        undefined &&
                                                        mutilYear.lineAmount !=
                                                        0 ? (
                                                        <div>
                                                          <InputNumber
                                                            className="BSInputBox"
                                                            value={Math.round(
                                                              mutilYear.lineAmount
                                                            )}
                                                            onBlur={(e) =>
                                                              this.AmountOnBlur(
                                                                e,
                                                                mutilYear,
                                                                heading
                                                              )
                                                            }
                                                            useGrouping={true}
                                                            disabled={
                                                              linkSharedUser ==
                                                              3
                                                            }
                                                            locale="sv-SE"
                                                          />

                                                          {fieldExistsInMultiYear.length ==
                                                            multiYearIdx + 1 &&
                                                            !BSTemplateFieldOutsideMap.isCustomRow && (
                                                              <i
                                                                id="ISAddNewLineIcon"
                                                                className="fa fa-plus"
                                                                aria-hidden="true"
                                                                onClick={(e) =>
                                                                  this.onClickAddNewRow(
                                                                    heading,
                                                                    BSTemplateFieldOutsideMap
                                                                  )
                                                                }
                                                                title="Add New Line"
                                                                style={{
                                                                  display:
                                                                    linkSharedUser ==
                                                                    3 &&
                                                                    "none",
                                                                }}
                                                              ></i>
                                                            )}
                                                        </div>
                                                      ) : (
                                                        !mutilYear.isHeader && (
                                                          <div
                                                          // style={{display:'grid',gridTemplateColumns:'repeat(2,1fr)'}}
                                                          >
                                                            <InputNumber
                                                              // style={{ border: "1px solid red " }}
                                                              className="BSInputBox"
                                                              onBlur={(e) =>
                                                                this.AmountOnBlur(
                                                                  e,
                                                                  mutilYear,
                                                                  heading
                                                                )
                                                              }
                                                              useGrouping={true}
                                                              disabled={
                                                                linkSharedUser ==
                                                                3 ||
                                                                mutilYear.name ==
                                                                ""
                                                              }
                                                              locale="sv-SE"
                                                            />

                                                            {fieldExistsInMultiYear.length ==
                                                              multiYearIdx +
                                                              1 &&
                                                              !BSTemplateFieldOutsideMap.isCustomRow && (
                                                                <i
                                                                  id="ISAddNewLineIcon"
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.onClickAddNewRow(
                                                                      heading,
                                                                      BSTemplateFieldOutsideMap
                                                                    )
                                                                  }
                                                                  title="Add New Line"
                                                                  style={{
                                                                    display:
                                                                      linkSharedUser ==
                                                                      3 &&
                                                                      "none",
                                                                  }}
                                                                ></i>
                                                              )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  );
                                              }
                                            )}
                                            {fieldExistsInMultiYear &&
                                              fieldExistsInMultiYear.length &&
                                              fieldExistsInMultiYear[0]
                                                .isCustomRow ? (
                                              <i
                                                id="BSCustomRowDelete"
                                                className="pi pi-trash"
                                                style={{
                                                  display:
                                                    linkSharedUser == 3 &&
                                                    "none",
                                                }}
                                                onClick={this.deleteCustomRow.bind(
                                                  this,
                                                  fieldExistsInMultiYear,
                                                  heading
                                                )}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </AccordionTab>
                        );
                      }
                    )}
                  </Accordion>
                )}
              </Col>
              <center className="BSSaveBtnCenter">
                <Button
                  label={TranslationFile.previousBtn[userSelectedLanguage]}
                  aria-label="Annual Report"
                  onClick={() => this.pageNavigation("incomeStatement")}
                  id="annualReportBtn"
                  className="BSSaveBtn"
                />
                <Button
                  label={TranslationFile.nextBtn[userSelectedLanguage]}
                  aria-label="Annual Report"
                  onClick={() => this.pageNavigation("notes")}
                  id="annualReportBtn"
                  className="BSSaveBtn"
                />
              </center>
            </Row>
          )}
        </div>

        <Dialog
          header={TranslationFile.balanceSheetWarningTag[userSelectedLanguage]}
          visible={this.state.showDialog}
          modal
          className="balance-warning-dialog"
          // style={{ width: "350px" }}
          footer={this.renderFooter()}
          onHide={() => this.onHide()}
          blockScroll
        >
          <div className="confirmation-content">
            <span>
              {TranslationFile.balanceSheetWarningTest1[userSelectedLanguage]}
              <br />
              <br />
              {lineSumArray != undefined && (
                <div>
                  {
                    TranslationFile.balanceSheetWarningText2[
                    userSelectedLanguage
                    ]
                  }
                  {lineSumArray[0] != undefined &&
                    Number(lineSumArray[0].toFixed(0)).toLocaleString()}
                  Kr
                  <br />
                  {
                    TranslationFile.balanceSheetWarningText3[
                    userSelectedLanguage
                    ]
                  }
                  {lineSumArray[1] != undefined &&
                    Number(lineSumArray[1].toFixed(0)).toLocaleString()}
                  Kr
                  <br />
                  <div className="bsDifferencesMainDiv">
                    {
                      TranslationFile.balanceSheetWarningDiff[
                      userSelectedLanguage
                      ]
                    }{" "}
                    {lineSumArray[0] != undefined &&
                      lineSumArray[1] != undefined &&
                      Number(
                        (lineSumArray[0] - Math.abs(lineSumArray[1])).toFixed(0)
                      ).toLocaleString()}{" "}
                    kr
                  </div>
                  <br />
                  <div>{TranslationFile.yesBtnInfo[userSelectedLanguage]}</div>
                </div>
              )}
            </span>
          </div>
        </Dialog>
        <ScrolltoTop />
        {/* <Footerpage /> */}
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(BalanceSheetNew);
