import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import svFlag from "../../Assests/sweden_flag.webp";
import enFlag from "../../Assests/USUK_Flag.jpg";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { RiArrowDownSFill } from "react-icons/ri";
import MenuItem from "../Menu/MenuItem";
import { Image } from "primereact/image";
import "./Language.css";
let langFromLS;
const Language = ({ languageValue }) => {
  const countries = [
    { name: "Svenska", code: "SV", image: svFlag },
    { name: "English", code: "EN", image: enFlag },
  ];

  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [language, setLanguage] = useState("sw");
  const languageReducer = useSelector((state) => state.language);

  langFromLS = localStorage.getItem('selectedLanguage')

  useEffect(() => {
    localStorage.setItem('selectedLanguage','sw')
}, []); 

  const flagOnClick = (name) => {
    if (name == "Svenska") {
      // languageValue("sw");
      
      localStorage.setItem('selectedLanguage','sw')
      setLanguage("sw")
      window.location.reload(false)
    } else {
      // languageValue("en");
      
      localStorage.setItem('selectedLanguage','en')
      setLanguage("en")
      window.location.reload(false)
    }
  };

  return (
    <div>
      <div>
        <ul style={{ display: "flex", position: "relative" }}>
          {/* <li className="lang-link" style={{ height: "46px" }}> */}
          <li className="lang-link">
            <a className="language-dropdown">
              <span>
                <Image
                  src={langFromLS && langFromLS == 'sw' ? countries[0].image : countries[1].image}
                  // src={countries[0].image}
                  alt="Image"
                  width="35"
                  className="lang-menu-mobile"
                />
              </span>

              <span>
                <RiArrowDownSFill className="drop-down-black-fill-icon-login" />
              </span>
            </a>
            <div className="lang-dropdownT">
              <ul className="lang-dropdownT-ul">
                <li class="lang-dropdownT-link">
                  <a>
                    <span>
                      <Image
                        src={countries[0].image}
                        alt="Image"
                        width="35"
                        className="lang-menu-mobile"
                        onClick={() => flagOnClick(countries[0].name)}
                      />
                    </span>
                  </a>
                </li>
                <li class="lang-dropdownT-link">
                  <a>
                    <span>
                      <Image
                        src={countries[1].image}
                        alt="Image"
                        width="35"
                        className="lang-menu-mobile"
                        onClick={(e) => flagOnClick(countries[1].name)}
                      />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      
    </div>
  );
};

export default Language;