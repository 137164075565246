import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./Template.css";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { setUserSessionAuthKey } from "../../Component/commonUtils";

const statementType = [
  { name: "Income Statement", code: 1 },
  { name: "Balance Sheet", code: 2 },
  { name: "Notes", code: 3 },
];

const lineType = [
  { name: "Header", code: 1 },
  { name: "Line", code: 2 },
  { name: "Line Sum", code: 3 },
  { name: "Final Sum", code: 4 },
];

const lineLevel = [
  { name: 1, code: 1 },
  { name: 2, code: 2 },
  { name: 3, code: 3 },
  { name: 4, code: 4 },
];

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
  };
};

let selectedFile = "";
let fromDateValue = "",
  toDateValue = "";
class Template extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      visible: false,
      choosenFile: "",
      totalSize: 0,
      dummy: "",
      showProceedButton: false,
      templateLinesGrid: [],
      fromDate: "",
      toDate: "",
    };
  }

  componentDidMount() {
    axios.get("/annualReportTemplate/getLines").then((resp) => {
      if (resp.data.length > 0) {
        this.setState({
          templateLinesGrid: resp.data,
        });
      }
    });
  }

  test() {
    if (userSessionAuthKeyReturnValue) {
      var file = selectedFile;
      const url = "/annualReportTemplate/insertLines";
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "User-Session-Auth-Key":
            userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
        },
      };

      axios.post(url, formData, config).then((response) => {
        if (response.data) {
          axios.get("/annualReportTemplate/getLines").then((resp) => {
            if (resp.data.length > 0) {
              this.setState({
                templateLinesGrid: resp.data,
              });
            }
          });
        }
      });

      var reader = new FileReader();
      reader.onload = function (e) {};

      this.setState({ visible: false, showProceedButton: false });
    }
  }

  selectFile = (selectedFileCallback) => {
    selectedFile = selectedFileCallback.files[0];

    let selectedFileFormat = selectedFileCallback.files[0].name
      .split(".")
      .pop();

    this.setState({
      showProceedButton: true,
    });

    this.setState({
      dummy: "",
    });
  };

  //   test() {
  //     var file = selectedFile;
  //     var reader = new FileReader();
  //     reader.onload = function (e) {
  //         // var data = e.target.result;
  //         // let readedData = XLSX.read(data, {type: 'binary'});
  //         // const wsname = readedData.SheetNames[0];
  //         // const ws = readedData.Sheets[wsname];

  //         // /* Convert array to json*/
  //         // const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
  //         //setFileUploaded(dataParse);
  //     };

  //     //reader.readAsText(file);
  //     //reader.readAsBinaryString(f)
  //     //reader.readAsArrayBuffer(e.target.files[0])

  textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  validFromDateEditor = (options) => {
    fromDateValue = new Date(options.value);
    return (
      <Calendar
        value={fromDateValue}
        onChange={(e) => (fromDateValue = e.value)}
        dateFormat="yy-mm-dd"
      />
    );
  };

  validToDateEditor = (options) => {
    toDateValue = new Date(options.value);
    return (
      <Calendar
        value={toDateValue}
        onChange={(e) => (toDateValue = e.value)}
        dateFormat="yy-mm-dd"
      />
    );
  };

  onRowEditComplete = (e) => {
    let { newData, index, data } = e;
    if (userSessionAuthKeyReturnValue) {
      let statementTypeObj = {
        name: "",
        code: "",
      };

      let lineTypeObj = {
        name: "",
        code: "",
      };

      if (typeof newData.statementType == "string") {
        statementType.map((i, idx) => {
          if (i.name == newData.statementType) {
            statementTypeObj.code = i.code;
            statementTypeObj.name = i.name;
          }
        });
      }

      if (typeof newData.lineType == "string") {
        lineType.map((i, idx) => {
          if (i.name == newData.lineType) {
            lineTypeObj.code = i.code;
            lineTypeObj.name = i.name;
          }
        });
      }

      let updateTemplateLinesReq = {
        no: newData.no,
        validFromDate: fromDateValue,
        validToDate: toDateValue,
        statementType:
          typeof newData.statementType == "string"
            ? statementTypeObj.code
            : newData.statementType.code,
        lineType:
          typeof newData.lineType == "string"
            ? lineTypeObj.code
            : newData.lineType.code,
        lineNo: newData.lineNo,
        accountDescription: newData.accDesc,
        mapToAccountNos: newData.mapToAccNos,
        sKMappingRange: newData.sKMappingRange,
        isFixedAsset: newData.isFixedAsset,
        isEmployeeAccount: newData.isEmployeeAcc,
      };

      if (fromDateValue == "Invalid Date") {
        const fromDate = new Date(newData.validFromDate);
        updateTemplateLinesReq.validFromDate = fromDate.getTime();
      } else {
        const fromDate = new Date(fromDateValue);
        updateTemplateLinesReq.validFromDate = fromDate.getTime();
      }

      if (toDateValue == "") {
        updateTemplateLinesReq.validToDate = 0;
      }

      if (toDateValue == "Invalid Date") {
        if (newData.validToDate == "") {
          updateTemplateLinesReq.validToDate = 0;
        } else {
          const toDate = new Date(newData.validToDate);
          updateTemplateLinesReq.validToDate = toDate.getTime();
        }
      } else {
        const toDate = new Date(toDateValue);
        updateTemplateLinesReq.validToDate = toDate.getTime();
      }

      if (
        updateTemplateLinesReq.validFromDate !== "" &&
        updateTemplateLinesReq.accDesc != ""
      ) {
        axios
          .post(
            "/annualReportTemplate/updateLines",
            updateTemplateLinesReq,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              axios.get("/annualReportTemplate/getLines").then((resp) => {
                if (resp.data.length > 0) {
                  this.setState({
                    templateLinesGrid: resp.data,
                  });
                }
              });
            }
          });
      }
    }
  };

  onRowEditInit(e) {}

  fromDateString(e) {
    const date = new Date(e.validFromDate);
    let formattedFromDate = moment(date).format("yyy-MM-DD");
    return formattedFromDate;
  }

  toDateString(e) {
    if (e.validToDate != "") {
      const fromDate = new Date(e.validFromDate);
      let formattedFromDate = moment(fromDate).format("yyy-MM-DD");

      const toDate = new Date(e.validToDate);
      let formattedToDate = moment(toDate).format("yyy-MM-DD");

      if (formattedToDate < formattedFromDate) return "";
      else return formattedToDate;
    } else {
      return "";
    }
  }

  isFixedAssestBody(e) {
    return (
      <Checkbox
        inputId="binary"
        checked={e.isFixedAsset}
        onChange={(e) => this.setState({ fixedAssetChecked: e.checked })}
        disabled
      />
    );
  }

  isEmployeeAccountBody(e) {
    return (
      <Checkbox
        inputId="binary"
        checked={e.isEmployeeAcc}
        onChange={(e) => this.setState({ emplyeeAccChecked: e.checked })}
        disabled
      />
    );
  }

  isCheckBoxEditor = (options) => {
    return (
      <Checkbox
        inputId="binary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.checked)}
      />
    );
  };

  isCheckBoxEditorEmp = (options) => {
    return (
      <Checkbox
        inputId="binary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.checked)}
      />
    );
  };

  statementTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statementType}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  lineTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={lineType}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  lineLevelEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={lineLevel}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  sumLineNosEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  deleteTemplate() {
    if (userSessionAuthKeyReturnValue) {
      axios
        .get("/annualReportTemplate/deleteLines", userSessionAuthKeyReturnValue)
        .then((resp) => {
          if (resp.data) {
            this.setState({
              templateLinesGrid: [],
            });
          }
        });
    }
  }

  render() {
    const { showProceedButton, templateLinesGrid } = this.state;
    return (
      <div className="importTemplate-div-upload">
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          position="center"
          className="wrongSIEFileUpload"
        ></Toast>
        <div className="template-file-upload">
          <center>
            <Button
              label="Import Template"
              className="import-btn-upload"
              icon="pi pi-file-import"
              onClick={() => this.setState({ visible: true })}
            />

            <Button
              severity="danger"
              className="templatePageDeleteBtn"
              onClick={this.deleteTemplate.bind(this)}
              visible={templateLinesGrid.length > 0 ? true : false}
            >
              <i
                class="fa fa-trash"
                aria-hidden="true"
                id="tempkatePageDeleteIcon"
              ></i>
              Delete Template
            </Button>

            <Dialog
              header="Import template file"
              visible={this.state.visible}
              onHide={() => this.setState({ visible: false })}
              id="import-dialog"
              style={{ width: "50vw" }}
              onClick={this.dialogBoxOnClick}
            >
              <FileUpload
                maxFileSize={10000000000}
                name="demo[]"
                accept=".xlsx"
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
                onSelect={this.selectFile}
                multiple={false}
                onRemove={this.removeFile}
              />

              {showProceedButton != "" && (
                <div className="proceed-btn-div">
                  <button onClick={this.test.bind(this)}>Proceed</button>
                </div>
              )}
            </Dialog>
          </center>
        </div>
        <div className="template-data-table-div">
          <center>
            <DataTable
              value={templateLinesGrid}
              dataKey="id"
              stripedRows
              tableStyle={{ minWidth: "50rem" }}
              className="template-data-table"
              paginator
              rows={10}
              editMode="row"
              onRowEditComplete={this.onRowEditComplete}
              onRowEditInit={this.onRowEditInit}
              // rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column field="no" header="No"></Column>
              <Column
                field="validFromDate"
                header="Valid From"
                body={this.fromDateString}
                editor={(options) => this.validFromDateEditor(options)}
                className="templateValidFromTitle"
              ></Column>
              <Column
                field="validToDate"
                header="Valid To"
                body={this.toDateString}
                editor={(options) => this.validToDateEditor(options)}
                className="templateValidToTitle"
              ></Column>
              <Column
                field="statementType"
                header="Statement Type"
                editor={(options) => this.statementTypeEditor(options)}
                // body={this.roleBodyTemplate}
              ></Column>
              <Column
                field="lineType"
                header="Line Type"
                editor={(options) => this.lineTypeEditor(options)}
              ></Column>
              <Column
                field="lineLevel"
                header="Line Level"
                editor={(options) => this.lineLevelEditor(options)}
              ></Column>
              <Column
                field="sumLineNos"
                header="Sum Line Nos."
                editor={(options) => this.sumLineNosEditor(options)}
              ></Column>
              <Column
                field="accDesc"
                header="Account Description"
                editor={(options) => this.textEditor(options)}
                className="templateAccDescTitle"
              ></Column>
              <Column
                field="mapToAccNos"
                header="Mapping Nos."
                editor={(options) => this.textEditor(options)}
              ></Column>
              <Column
                field="sKMappingRange"
                header="SK Mapping Range"
                editor={(options) => this.textEditor(options)}
                className="templateSkMapTitle"
              ></Column>
              <Column
                field="isFixedAsset"
                header="isFixedAsset"
                editor={(options) => this.isCheckBoxEditor(options)}
                body={this.isFixedAssestBody}
              ></Column>
              <Column
                field="isEmployeeAcc"
                header="isEmployeeAcc"
                editor={(options) => this.isCheckBoxEditorEmp(options)}
                body={this.isEmployeeAccountBody}
              ></Column>
              <Column
                rowEditor
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          </center>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(Template);
